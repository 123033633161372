const state = {
  user: {},
  notification: null,
  loading: {
    visible: false,
    text: null,
    pixel: {}
  },
  editMode: false,
  imageModal: {
    show: false,
    src: null
  }
}

const actions = {
  loading ({ commit }, data) {
    commit('SET_LOADING', data);
  },
  setNotification (context, response) {
    context.commit('SET_NOTIFICATION', response);
  },
  resetNotification (context) {
    context.commit('SET_NOTIFICATION');
  },
  setEditMode ({ commit }, payload) {
    commit('SET_EDIT_MODE', payload)
  },
  setImageModal ({ commit }, payload) {
    commit('SET_IMAGE_MODAL', payload)
  }
}

const mutations = {
  SET_LOADING (state, data) {
    const loading = data || { ...state.loading, visible: false, pixel: {} };
    state.loading = loading;
  },
  SET_NOTIFICATION (state, notification) {
    state.notification = notification;
  },
  SET_EDIT_MODE (state, payload) {
    state.editMode = payload;
  },
  SET_IMAGE_MODAL (state, payload) {
    state.imageModal.show = payload.show ? payload.show : false
    state.imageModal.src = payload.src ? payload.src : null
  }
}

const getters = {
  isLoading (state) {
    return state.loading.visible;
  },
  editMode (state) {
    return state.editMode
  },
  imageModal (state) {
    return state.imageModal;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
