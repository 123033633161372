import arrayHelper from '@/helpers/array';

export default {
  data () {
    return {
      currentLanguage: null,
      languages: []
    }
  },
  beforeMount () {
    this.currentLanguage = this.$i18n.locale;
    this.languages = arrayHelper.sort(this.$t('general.languages'), 'label');

    // When locale has been forced by an URL query param (?locale=)
    //  Then we should update the local storage value...
    this.updateLocalStorageValue();
  },
  watch: {
    currentLanguage () {
      this.changeLanguage();
    }
  },
  methods: {
    changeLanguage () {
      if (this.currentLanguage === this.$i18n.locale) return;

      // Update the value in local storage...
      this.storeLocale();

      // Force reload current page without locale query param...
      window.location.href = window.location.href.replace(/\?locale=(.+)$/, '');
    },
    async updateLocalStorageValue () {
      const locale = await this.$storage.getItem('locale');

      if (this.currentLanguage === locale) return;

      this.storeLocale();
    },
    storeLocale () {
      const LOCALE_KEY = this.$config.get('GLOBAL.LOCAL_STORAGE_LOCALE_KEY');
      this.$storage.setItem(LOCALE_KEY, this.currentLanguage);
    }
  }
}
