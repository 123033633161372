import Api from '@/services/api'
import i18n from '@/services/localization';

import shopHelper from '@/helpers/shop'

export default {
  getShopInventory () {
    const resource = '/shop'
    return Api().get(`${resource}`);
  },
  getShopProduct (productKey) {
    return new Promise((resolve, reject) => {
      const product = i18n.te(`shop.product.${productKey}`) ? i18n.t(`shop.product.${productKey}`) : null;

      if (product) {
        resolve(product);
      } else {
        reject(new Error('Product not found.'))
      }
    })
  },
  getShopProducts () {
    return new Promise((resolve, reject) => {
      const products = i18n.te('shop.product') ? i18n.t('shop.product') : null;

      if (!products) {
        reject(new Error('Products not found.'))
      }

      const values = Object.values(products)
      const keys = Object.keys(products)
      const productsMap = values.map((value, index) => {
        const productSlug = shopHelper.getProductSlug(keys[index])
        return { ...value, id: productSlug }
      })

      resolve(productsMap);
    })
  },
  createShopOrder (data) {
    const resource = '/shop/order'
    return Api().post(`${resource}`, data);
  },
  createShopRequest ({ fieldId, productCode, returnUrl }) {
    const resource = '/shop/request'
    return Api().post(`${resource}`, {
      field_id: fieldId,
      product_code: productCode,
      return_url: returnUrl
    });
  }
}
