/**
 *
 * Field
 *
 * Field constants
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const SHOW_FIELD_TIMEOUT_MS = 400;

const LONG_PRESS_DURATION_MS = 600;
const LONG_PRESS_SPINNER_SIZE = {
  width: 76,
  height: 76
};

const COLORMAP_TYPES = {
  absolute: 'absolute', // dynamic
  relative: 'relative' // static
}

const DEFAULT_COLORMAP_TYPE = 'absolute';

/**
 * Timeslider
 *
 * Component only works when the result of Width (inc additional right margin) divided
 *  by total List Item width (inc right margin) results in an odd value..
 *
 * Calculated example:
 *  ((244 + 4) / 8) = 31 items
 *  TIME_SLIDER_WIDTH = 244
 *  TIME_SLIDER_LIST_ITEM_WIDTH = 4
 *  TIME_SLIDER_LIST_MARGIN_RIGHT = 4
 *
 */

const TIME_SLIDER_WIDTH = 244;
const TIME_SLIDER_LIST_ITEM_WIDTH = 4;
const TIME_SLIDER_LIST_ITEM_MARGIN_RIGHT = 4;

const SEASONS_START_YEAR = 2021;

const KEY_NAME = 'name';
const KEY_LOCATION = 'location_name';
const KEY_IRRIGATION_ALERT_ENABLED = 'irrigation_alert_enabled';
const KEY_SMS_NOTIFICATIONS = 'sms_notifications';
const KEY_AREA_IN_HA = 'properties.area_in_ha';
const KEY_NOTES_COUNT = 'notes_count';
const KEY_SEASONS = 'seasons';
const KEY_CROP_TYPES = {
  crop: 'crop_code',
  crop_variety: 'crop_variety_code'
};

export default {
  SHOW_FIELD_TIMEOUT_MS,
  LONG_PRESS_DURATION_MS,
  LONG_PRESS_SPINNER_SIZE,
  COLORMAP_TYPES,
  DEFAULT_COLORMAP_TYPE,
  SEASONS_START_YEAR,
  KEY_NAME,
  KEY_LOCATION,
  KEY_IRRIGATION_ALERT_ENABLED,
  KEY_SMS_NOTIFICATIONS,
  KEY_AREA_IN_HA,
  KEY_NOTES_COUNT,
  KEY_SEASONS,
  KEY_CROP_TYPES,
  TIME_SLIDER_WIDTH,
  TIME_SLIDER_LIST_ITEM_WIDTH,
  TIME_SLIDER_LIST_ITEM_MARGIN_RIGHT
};
