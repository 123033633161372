/**
 * Home
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import i18n from '@/services/localization';

const Home = () => import(/* webpackChunkName: "users" */ '@/pages/users/Home.vue')
const Login = () => import(/* webpackChunkName: "users" */ '@/pages/users/Login.vue')
const Logout = () => import(/* webpackChunkName: "users" */ '@/pages/users/Logout.vue')
const Create = () => import(/* webpackChunkName: "users" */ '@/pages/users/Create.vue')
const Edit = () => import(/* webpackChunkName: "users" */ '@/pages/users/Edit.vue')
const PasswordRequestForm = () => import(/* webpackChunkName: "users" */ '@/pages/users/PasswordRequestForm.vue')
const PasswordResetForm = () => import(/* webpackChunkName: "users" */ '@/pages/users/PasswordResetForm.vue')
const Confirm = () => import(/* webpackChunkName: "users" */ '@/pages/users/Confirm.vue')

export default [
  /**
   * @route /users
   * @desc Entry redirects to /login
   */
  {
    path: '/users',
    name: 'users',
    component: Home,
    redirect: '/users/login',
    children: [
      /**
       * @route /users/login
       * @desc Login user
       */
      {
        path: '/users/login',
        name: 'users.login',
        component: Login,
        props: {
          title: 'users.login.title',
          image: {
            file: 'created.png'
          }
        },
        meta: {
          title: i18n.t('browser_title.user_login'),
          gtm: i18n.t('browser_title.user_login'),
          detectScreenSize: false
        }
      },
      /**
       * @route /users/login
       * @desc Login user
       */
      {
        path: '/users/logout',
        name: 'users.logout',
        component: Logout,
        props: {
          title: 'users.logout.title',
          content: 'users.logout.content',
          image: {
            file: 'created.png'
          }
        },
        meta: {
          title: i18n.t('browser_title.user_logout'),
          gtm: i18n.t('browser_title.user_logout'),
          requiresAuth: true,
          detectScreenSize: false
        }
      },
      /**
       * @route /users/new
       * @desc Register new user
       */
      {
        path: '/users/new',
        name: 'users.create',
        component: Create,
        props: {
          title: 'users.create.title',
          image: {
            file: 'created.png'
          }
        },
        meta: {
          title: i18n.t('browser_title.user_create'),
          gtm: i18n.t('browser_title.user_create'),
          detectScreenSize: false
        }
      },
      /**
       * @route /users/new/confirm
       * @desc Confirm page when user has been created
       */
      {
        path: '/users/new/confirm',
        name: 'users.new.confirm',
        component: Confirm,
        props: {
          title: 'users.create_confirm.title',
          content: 'users.create_confirm.content',
          image: {
            file: 'created.png'
          }
        },
        meta: {
          title: i18n.t('browser_title.user_create_confirm'),
          gtm: i18n.t('browser_title.user_create_confirm'),
          detectScreenSize: false
        }
      },
      /**
       * @route /users/:id/edit
       * @desc Edit user
       * @desc !!CURRENTLY HANDLED BY LARAVEL!!
       */
      {
        path: '/users/:id/edit',
        name: 'users.edit',
        component: Edit,
        props: {
          title: 'users.edit.title',
          image: {
            file: 'created.png'
          }
        },
        meta: {
          title: i18n.t('browser_title.user_edit'),
          gtm: i18n.t('browser_title.user_edit'),
          isActive: process.env.NODE_ENV === 'development',
          detectScreenSize: false
        }
      },
      /**
       * @route /users/password-reset-form
       * @desc Form which sends request token to reset password
       */
      {
        path: '/users/password-reset-form',
        name: 'users.password-reset-form',
        component: PasswordRequestForm,
        props: {
          title: 'users.password_request_form.title',
          image: {
            file: 'created.png'
          }
        },
        meta: {
          title: i18n.t('browser_title.user_password_request_form'),
          gtm: i18n.t('browser_title.user_password_request_form'),
          detectScreenSize: false
        }
      },
      /**
       * @route /users/password-reset-form/confirm
       * @desc Confirm page when user requested password reset
       */
      {
        path: '/users/password-reset-form/confirm',
        name: 'users.password-reset-form.confirm',
        component: Confirm,
        props: {
          title: 'users.password_request_form_confirm.title',
          content: 'users.password_request_form_confirm.content',
          image: {
            file: 'created.png'
          },
          buttons: [
            {
              to: '/users/login',
              text: 'general.login',
              class: 'button-primary'
            }
          ]
        },
        meta: {
          title: i18n.t('browser_title.user_password_request_form_confirm'),
          gtm: i18n.t('browser_title.user_password_request_form_confirm'),
          detectScreenSize: false
        }
      },
      /**
       * @route /users/:id/password-reset/:token
       * @desc Form to set a new user password
       */
      {
        path: '/users/:id/password-reset/:token',
        name: 'users.password-reset',
        component: PasswordResetForm,
        props: {
          title: 'users.password_request_form.title',
          image: {
            file: 'created.png'
          }
        },
        meta: {
          title: i18n.t('browser_title.user_password_request_form'),
          gtm: i18n.t('browser_title.user_password_request_form'),
          detectScreenSize: false
        }
      },
      /**
       * @route /users/new/confirm
       * @desc Confirm page when user has been created
       */
      {
        path: '/users/password-reset/confirm',
        name: 'users.password-reset.confirm',
        component: Confirm,
        props: {
          title: 'users.password_reset_form_confirm.title',
          content: 'users.password_reset_form_confirm.content',
          image: {
            file: 'created.png'
          },
          buttons: [
            {
              to: '/users/login',
              text: 'general.login',
              class: 'button-primary'
            }
          ]
        },
        meta: {
          title: i18n.t('browser_title.user_password_reset_form_confirm'),
          gtm: i18n.t('browser_title.user_password_reset_form_confirm'),
          detectScreenSize: false
        }
      }
    ]
  }
]
