/**
 * Home
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import i18n from '@/services/localization';

const Styleguide = () => import(/* webpackChunkName: "styleguide" */ '@/pages/Styleguide')

export default [
  {
    path: '/styleguide',
    name: 'styleguide',
    component: Styleguide,
    meta: {
      title: i18n.t('browser_title.styleguide'),
      gtm: i18n.t('browser_title.styleguide'),
      isActive: process.env.NODE_ENV !== 'production',
      detectScreenSize: false
    }
  }
]
