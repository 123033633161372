/**
 *
 * Carousel
 *  All Carousel constants...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const HOME = {
  interval: true,
  guid: 'homepage',
  items: [
    {
      image: '/image/illustration/usp-1.png'
    },
    {
      image: '/image/illustration/usp-2.png'
    },
    {
      image: '/image/illustration/usp-3.png'
    }
  ]
}

export default {
  TIMER: 6000,
  HOME
}
