const state = {
  panel: {
    title: null,
    showHeader: true
  },
  navigation: {
    navigationExpanded: false,
    navigationOpen: false,
    activeMenuItem: null,
    sidePanel: {
      open: false,
      title: null,
      parentRoute: null,
      component: {
        type: null,
        data: null
      }
    }
  }
}

const actions = {
  setNavigation ({ commit }, data) {
    commit('SET_NAVIGATION', data);
  },
  setSidePanel ({ commit }, payload) {
    commit('SET_SIDEPANEL', payload)
  },
  setPanel ({ commit }, payload) {
    commit('SET_PANEL', payload)
  },
  closeSidePanel ({ commit }) {
    commit('CLOSE_SIDEPANEL')
  },
  showHeader ({ commit }, payload) {
    commit('SHOW_HEADER', payload)
  }
}

const mutations = {
  SET_NAVIGATION (state, data) {
    state.navigation = { ...state.navigation, ...data };
  },
  SET_PANEL (state, data) {
    state.panel = { ...state.panel, ...data };
  },
  SET_SIDEPANEL (state, payload) {
    if (payload && payload.type) {
      state.navigation.sidePanel.component = {
        type: payload.type,
        data: payload.data ? payload.data : null
      }
      state.navigation.sidePanel.parentRoute = payload.parentRoute ? payload.parentRoute : null
      state.navigation.sidePanel.title = payload.title ? payload.title : null
      state.navigation.sidePanel.open = true
    }
  },
  CLOSE_SIDEPANEL (state) {
    state.navigation.sidePanel.open = false;
    state.navigation.sidePanel.title = ''
    state.navigation.sidePanel.component = null
  },
  SHOW_HEADER (state, payload) {
    state.panel.showHeader = payload;
  }
}

const getters = {
  activeMenuItem: state => state.navigation.activeMenuItem,
  navigationExpanded: state => state.navigation.navigationExpanded,
  navigationOpen: state => state.navigation.navigationOpen,
  sidePanel: state => state.navigation.sidePanel,
  sidePanelComponentData: state => state.navigation.sidePanel.component.data || {},
  panel: state => state.panel
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
