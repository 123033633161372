import Api from '@/services/api'

export default {
  getSeasons () {
    return Api().get('/seasons');
  },
  getAllFields (year) {
    console.log('getAllFields', year)
    return Api().get('/fields', { params: { season: year } });
  },
  getFieldById (fieldId) {
    return Api().get(`/fields/${fieldId}`);
  },
  updateFieldById (fieldId, body) {
    return Api().put(`/fields/${fieldId}`, body);
  },
  updateSeasonsFieldById (fieldId, body) {
    return Api().put(`/fields/${fieldId}/seasons`, body);
  },
  deleteFieldById (fieldId) {
    return Api().delete(`/fields/${fieldId}`);
  },
  create (geoJsonString) {
    return Api().post('/fields', {
      geojson: geoJsonString
    });
  },
  find (coordinate) {
    return Api().get('/fields/find', { params: coordinate });
  },
  getFieldStatistics (fieldId, params) {
    return Api().get(`/fields/${fieldId}/statistics-chartjs`, { params });
  },
  getFieldSoilMoisture (fieldId, params) {
    return Api().get(`/fields/${fieldId}/soil-moisture-graph`, { params });
  },
  getFieldProducts (fieldId) {
    return Api().get(`/fields/${fieldId}/products`)
  },
  getFieldProductLayers (fieldId, { code, scale }) {
    return Api().get(`/fields/${fieldId}/layers`, {
      params: {
        code,
        scale
      }
    })
  },
  getAllCropTypes () {
    return Api().get('/crops');
  },
  resetBrpField (fieldId) {
    return Api().get(`/fields/${fieldId}/reset`);
  },
  getProductLayers (fieldId, payload) {
    delete payload.id;
    return Api().get(`/fields/${fieldId}/layers`, { params: payload });
  },
  report (fieldId, layerId) {
    return Api().post(`/fields/${fieldId}/layers/${layerId}/report`);
  }
}
