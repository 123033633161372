import Vue from 'vue';

// Async
const AppButtons = () => import(/* webpackChunkName: "components" */ './AppButtons.vue');
const AppFooter = () => import(/* webpackChunkName: "components" */ './AppFooter.vue');
const AppPanel = () => import(/* webpackChunkName: "components" */ './AppPanel.vue');
const AppPanelHeaderField = () => import(/* webpackChunkName: "components" */ './AppPanelHeaderField.vue');
const AppPanelHeaderShop = () => import(/* webpackChunkName: "components" */ './AppPanelHeaderShop.vue');
const BenchmarkFilterListView = () => import(/* webpackChunkName: "components" */ './BenchmarkFilterListView.vue');
const BenchmarkFilterOptions = () => import(/* webpackChunkName: "components" */ './BenchmarkFilterOptions.vue');
const Carousel = () => import(/* webpackChunkName: "components" */ './Carousel.vue');
const CarouselImage = () => import(/* webpackChunkName: "components" */ './CarouselImage.vue');
const DetectScreenSize = () => import(/* webpackChunkName: "components" */ './DetectScreenSize.vue');
const DiscardEditMode = () => import(/* webpackChunkName: "components" */ './DiscardEditMode.vue');
const Dropdown = () => import(/* webpackChunkName: "components" */ './Dropdown.vue');
const FeedbackMessage = () => import(/* webpackChunkName: "components" */ './FeedbackMessage.vue');
const FieldsListView = () => import(/* webpackChunkName: "components" */ './FieldsListView.vue');
const FormAccount = () => import(/* webpackChunkName: "components" */ './FormAccount.vue');
const FormField = () => import(/* webpackChunkName: "components" */ './FormField.vue');
const FormIrrigation = () => import(/* webpackChunkName: "components" */ './FormIrrigation.vue');
const GoogleLogo = () => import(/* webpackChunkName: "components" */ './GoogleLogo.vue');
const FormToggle = () => import(/* webpackChunkName: "components" */ './FormToggle.vue');
const GoogleMap = () => import(/* webpackChunkName: "components" */ './GoogleMap.vue');
const Icon = () => import(/* webpackChunkName: "components" */ './Icon.vue');
const InformationList = () => import(/* webpackChunkName: "components" */ './InformationList.vue');
const InlineNotification = () => import(/* webpackChunkName: "components" */ './InlineNotification.vue');
const InteractiveList = () => import(/* webpackChunkName: "components" */ './InteractiveList.vue');
const InteractiveListButton = () => import(/* webpackChunkName: "components" */ './InteractiveListButton.vue');
const IrrigationsListView = () => import(/* webpackChunkName: "components" */ './IrrigationsListView.vue');
const LanguageSwitchSelect = () => import(/* webpackChunkName: "components" */ './LanguageSwitchSelect.vue');
const LanguageSwitchDropdown = () => import(/* webpackChunkName: "components" */ './LanguageSwitchDropdown.vue');
const LinkTo = () => import(/* webpackChunkName: "components" */ './LinkTo.vue');
const Logo = () => import(/* webpackChunkName: "components" */ './Logo.vue');
const LottieAnimation = () => import(/* webpackChunkName: "components" */ './LottieAnimation.vue');
const ModalDeleteField = () => import(/* webpackChunkName: "components" */ './ModalDeleteField.vue');
const ModalDeleteIrrigation = () => import(/* webpackChunkName: "components" */ './ModalDeleteIrrigation.vue');
const ModalDeleteNote = () => import(/* webpackChunkName: "components" */ './ModalDeleteNote.vue');
const ModalDeletePhoto = () => import(/* webpackChunkName: "components" */ './ModalDeletePhoto.vue');
const ModalDiscardEditMode = () => import(/* webpackChunkName: "components" */ './ModalDiscardEditMode.vue');
const ModalDrawFieldConfirmResetBrp = () => import(/* webpackChunkName: "components" */ './ModalDrawFieldConfirmResetBrp.vue');
const ModalDrawFieldConfirmSave = () => import(/* webpackChunkName: "components" */ './ModalDrawFieldConfirmSave.vue');
const ModalDrawFieldError = () => import(/* webpackChunkName: "components" */ './ModalDrawFieldError.vue');
const ModalDrawFieldLegenda = () => import(/* webpackChunkName: "components" */ './ModalDrawFieldLegenda.vue');
const ModalDrawFieldNotEditable = () => import(/* webpackChunkName: "components" */ './ModalDrawFieldNotEditable.vue');
const ModalFieldNotFound = () => import(/* webpackChunkName: "components" */ './ModalFieldNotFound.vue');
const ModalNoFields = () => import(/* webpackChunkName: "components" */ './ModalNoFields.vue');
const ModalReportField = () => import(/* webpackChunkName: "components" */ './ModalReportField.vue');
const ModalImage = () => import(/* webpackChunkName: "components" */ './ModalImage.vue');
const ModalSubscriptionAssignFields = () => import(/* webpackChunkName: "components" */ './ModalSubscriptionAssignFields.vue');
const ModalTermsAndConditions = () => import(/* webpackChunkName: "components" */ './ModalTermsAndConditions.vue');
const Navigation = () => import(/* webpackChunkName: "components" */ './Navigation.vue');
const Notification = () => import(/* webpackChunkName: "components" */ './Notification.vue');
const NoteEdit = () => import(/* webpackChunkName: "components" */ './NoteEdit.vue');
const PageHeader = () => import(/* webpackChunkName: "components" */ './PageHeader.vue');
const ShopFieldSelector = () => import(/* webpackChunkName: "components" */ './ShopFieldSelector.vue');
const ShopFooter = () => import(/* webpackChunkName: "components" */ './ShopFooter.vue');
const ShopProductBanner = () => import(/* webpackChunkName: "components" */ './ShopProductBanner.vue');
const ShopProductSubscription = () => import(/* webpackChunkName: "components" */ './ShopProductSubscription.vue');
const ShopUserSubscriptions = () => import(/* webpackChunkName: "components" */ './ShopUserSubscriptions.vue');
const Spinner = () => import(/* webpackChunkName: "components" */ './Spinner.vue');
const TimeSlider = () => import(/* webpackChunkName: "components" */ './TimeSlider.vue');
const ToggleView = () => import(/* webpackChunkName: "components" */ './ToggleView.vue');

// Bind
Vue.component('AppButtons', AppButtons)
Vue.component('AppFooter', AppFooter);
Vue.component('AppPanel', AppPanel);
Vue.component('AppPanelHeaderField', AppPanelHeaderField);
Vue.component('AppPanelHeaderShop', AppPanelHeaderShop);
Vue.component('BenchmarkFilterListView', BenchmarkFilterListView);
Vue.component('BenchmarkFilterOptions', BenchmarkFilterOptions)
Vue.component('Carousel', Carousel);
Vue.component('CarouselImage', CarouselImage);
Vue.component('DetectScreenSize', DetectScreenSize);
Vue.component('DiscardEditMode', DiscardEditMode);
Vue.component('Dropdown', Dropdown);
Vue.component('FeedbackMessage', FeedbackMessage);
Vue.component('FieldsListView', FieldsListView)
Vue.component('FormAccount', FormAccount)
Vue.component('FormField', FormField);
Vue.component('FormIrrigation', FormIrrigation);
Vue.component('GoogleLogo', GoogleLogo);
Vue.component('FormToggle', FormToggle);
Vue.component('GoogleMap', GoogleMap);
Vue.component('Icon', Icon);
Vue.component('InformationList', InformationList);
Vue.component('InlineNotification', InlineNotification);
Vue.component('InteractiveList', InteractiveList);
Vue.component('InteractiveListButton', InteractiveListButton);
Vue.component('IrrigationsListView', IrrigationsListView)
Vue.component('LinkTo', LinkTo);
Vue.component('LanguageSwitchSelect', LanguageSwitchSelect)
Vue.component('LanguageSwitchDropdown', LanguageSwitchDropdown)
Vue.component('Logo', Logo);
Vue.component('LottieAnimation', LottieAnimation);
Vue.component('ModalDeleteField', ModalDeleteField);
Vue.component('ModalDeleteIrrigation', ModalDeleteIrrigation);
Vue.component('ModalDeleteNote', ModalDeleteNote);
Vue.component('ModalDeletePhoto', ModalDeletePhoto);
Vue.component('ModalDiscardEditMode', ModalDiscardEditMode);
Vue.component('ModalDrawFieldConfirmResetBrp', ModalDrawFieldConfirmResetBrp);
Vue.component('ModalDrawFieldConfirmSave', ModalDrawFieldConfirmSave);
Vue.component('ModalDrawFieldError', ModalDrawFieldError);
Vue.component('ModalDrawFieldLegenda', ModalDrawFieldLegenda);
Vue.component('ModalDrawFieldNotEditable', ModalDrawFieldNotEditable);
Vue.component('ModalFieldNotFound', ModalFieldNotFound);
Vue.component('ModalImage', ModalImage);
Vue.component('ModalNoFields', ModalNoFields);
Vue.component('ModalReportField', ModalReportField);
Vue.component('ModalSubscriptionAssignFields', ModalSubscriptionAssignFields);
Vue.component('ModalTermsAndConditions', ModalTermsAndConditions);
Vue.component('Navigation', Navigation);
Vue.component('Notification', Notification);
Vue.component('NoteEdit', NoteEdit);
Vue.component('PageHeader', PageHeader);
Vue.component('ShopFieldSelector', ShopFieldSelector)
Vue.component('ShopFooter', ShopFooter)
Vue.component('ShopProductBanner', ShopProductBanner)
Vue.component('ShopProductSubscription', ShopProductSubscription)
Vue.component('ShopUserSubscriptions', ShopUserSubscriptions)
Vue.component('Spinner', Spinner);
Vue.component('TimeSlider', TimeSlider);
Vue.component('ToggleView', ToggleView);
