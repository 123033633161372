import Api from '@/services/api'

export default {
  getAllNotes () {
    return Api().get('/notes');
  },
  getNotesByFieldId (fieldId) {
    return Api().get(`/fields/${fieldId}/notes`);
  },
  getNoteByNoteId (fieldId, noteId) {
    return Api().get(`/fields/${fieldId}/notes/${noteId}`);
  },
  createNote (fieldId, requestBody) {
    return Api().post(`/fields/${fieldId}/notes`, requestBody);
  },
  updateNoteById (noteId, fieldId, requestBody) {
    return Api().put(`/fields/${fieldId}/notes/${noteId}`, requestBody);
  },
  deletePhotoById (noteId, fieldId, photoId) {
    return Api().delete(`/fields/${fieldId}/notes/${noteId}/photos/${photoId}`);
  },
  deleteNoteById (noteId, fieldId) {
    return Api().delete(`/fields/${fieldId}/notes/${noteId}`);
  }
}
