/**
 *
 * Locale
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const DEFAULT = 'nl-NL';

const LABEL = {
  en: 'EN',
  nl: 'NL',
  es: 'ES',
  fr: 'FR',
  de: 'DE'
}

const DATE_FORMAT_SHORT = {
  en: 'YYYY-MM-DD',
  nl: 'DD-MM-YYYY',
  es: 'YYYY/MM/DD',
  fr: 'YYYY/MM/DD',
  de: 'YYYY/MM/DD'
}

const DATE_FORMAT_LONG = {
  en: 'E d MMM yyyy',
  nl: 'E d MMM yyyy',
  es: 'E d MMM yyyy',
  fr: 'E d MMM yyyy',
  de: 'E d MMM yyyy'
}

export default {
  DEFAULT,
  LABEL,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_LONG
};
