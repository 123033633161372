<template>
  <section
    class="d-flex flex-column justify-content-lg-center section-gradient"
  >
    <div class="homepage__authentication" v-if="!authenticated">
      <router-link to="/users/new" custom v-slot="{ navigate }">
        <button class="button-tertiary" @click="navigate">
          {{ $t('homepage.button.create_account') }}
        </button>
      </router-link>
      <router-link class="link-strong ml-lg-4" to="/users/login"
        >{{ $t('homepage.button.login') }}
        <icon class="d-inline-block ml-2" :name="iconAccount"></icon>
      </router-link>
      <language-switch-dropdown class="ml-4 d-none d-lg-inline-flex" />
    </div>
    <div class="homepage__content container">
      <language-switch-dropdown class="d-lg-none" />
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row justify-content-center">
            <div
              class="col-12 col-lg-4 d-flex flex-column justify-items-center"
            >
              <logo
                type="bioscope"
                color="white"
                url="http://www.bioscope.nl/"
                :alt="$t('general.company')"
                class="mt-4 mt-lg-3 pb-1 pb-lg-3"
              ></logo>
              <logo
                type="fieldscout"
                color="white"
                :alt="$t('general.app_name')"
                class="mb-1 mb-lg-3"
              ></logo>
              <carousel
                class="mt-4"
                :guid="carousel.guid"
                :items="carousel.items"
                :interval="carousel.interval"
              ></carousel>
              <app-buttons class="d-none d-lg-flex justify-content-start mt-5"></app-buttons>
            </div>
            <div class="col-12 col-lg-6">
              <carousel-image
                :guid="carousel.guid"
                :items="carousel.items"
              ></carousel-image>
              <app-buttons class="my-3 d-lg-none d-flex justify-content-center mt-3"></app-buttons>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      iconAccount: this.$config.get('ICONS.ACCOUNT_WHITE'),
      carousel: this.$config.get('CAROUSEL.HOME')
    }
  },
  computed: {
    ...mapState('users', {
      authenticated: (state) => state.authenticated
    })
  },
  mounted () {
    this.$root.$emit(this.$config.get('EVENTS.TOGGLE_SPINNER'), {
      guid: this.$config.get('GLOBAL.SPINNER_GUID'),
      active: false
    })
  }
}
</script>
