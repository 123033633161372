/**
 *
 * Link
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {
  ABOUT_MAPS: 'https://bioscope.nl/kaarten/',
  MANUAL: 'https://bioscope.nl/fieldscout/handleiding/',
  FAQ: 'https://bioscope.nl/fieldscout/fieldscout-faq/',
  SUPPORT: 'https://bioscope.nl/support/',
  EMAIL: 'info@bioscope.nl',
  CONTACT: 'https://bioscope.nl/contact/'
};
