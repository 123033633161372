/**
 * Date helper
 *
 * Helper methods for formatting date
 *
 * @author     K. Galstaun <k.galstaun@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import moment from 'moment';

export default {

  /**
   * isToday()
   *  Date is the same as today
   *
   * @return {boolean} true/false
   *
   */

  isToday (date) {
    const today = moment().endOf('day');
    return moment(date).startOf('day').isSame(today, 'day');
  },

  /**
   * isTomorrow()
   *  Date is the same as tomorrow
   *
   * @return {boolean} true/false
   *
   */

  isTomorrow (date) {
    const tomorrow = moment().add(1, 'day').endOf('day')
    return moment(date).startOf('day').isSame(tomorrow, 'day');
  },

  /**
   * isValidDate()
   *  Check if input is a valid date
   *
   * @return {boolean}
   *
   */

  isValidDate (date) {
    return moment(date).isValid();
  },

  /**
   * getDaysUntilDate()
   *  returns the amount of days until a specified date
   *
   * @return {number} days
   *
   */

  getDaysUntilDate (date) {
    const eventDate = moment(date).startOf('day');
    const nowDate = moment().startOf('day');

    return eventDate.diff(nowDate, 'days');
  },

  /**
   * getSeasonYears()
   *  returns an array of years from start year
   *
   * @return {number} start year
   *
   */

  getSeasonYears (start) {
    const currentYear = this.getCurrentYear();

    if (start >= currentYear) return [start];

    const years = Array(currentYear - start).fill('').map((v, idx) => currentYear - idx).sort()

    return [start, ...years];
  },

  /**
   * getCurrentYear()
   *  return the current year
   *
   * @return {number} current year
   *
   */

  getCurrentYear () {
    return new Date().getFullYear();
  },

  /**
   * convertTimestamp()
   *  return formatted time
   *
   * @param {string} timestamp
   * @param {string} locale
   *
   */

  convertTimestamp (timestamp, locale) {
    const time = this.toTime(timestamp, locale)
    const fullDate = this.toFullDate(timestamp, locale)
    return `${fullDate}  •  ${time}`
  },

  /**
   * toTime()
   *  return formatted time
   *
   * @param {string} timestamp
   *
   */

  toTime (timestamp, locale) {
    return new Date(timestamp).toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit'
    });
  },

  /**
   * toFullDate()
   *  return formatted date, first letter capitalized
   *
   * @param {string} timestamp
   *
   */

  toFullDate (timestamp, locale) {
    const date = new Date(timestamp).toLocaleDateString(locale, {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })

    return date.charAt(0).toUpperCase() + date.slice(1)
  },

  /**
   * formatDate()
   *  Formats a date as YYYY-MM-DD
   *
   * @param {date} Date object
   * @return {string} dateString YYYY-MM-DD
   *
   */

  formatDate (date, locale) {
    if (!date) return null;

    const dateString = new Date(date).toLocaleDateString(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).split('-').reverse()

    return dateString.join('-');
  },

  /**
   * formatDateMySQL()
   *
   */

  formatDateMySQL (date) {
    date = date && date instanceof Date ? date : new Date(date);
    return date ? moment(date).format('YYYY-MM-DD') : null
  },

  /**
   * getDate()
   *
   */

  getDate (value) {
    return new Date(value);
  },

  /**
   * getLocaleDate()
   *
   */

  getLocaleDate (value, locale, options) {
    return new Date(value).toLocaleDateString(locale, options);
  }
}
