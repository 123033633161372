/**
 * Url Helper
 *
 * Helper methods for url and paramaters
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

// import URLSearchParams from '@ungap/url-search-params'

export default {
  build (data, baseUrl) {
    let url = baseUrl || '';

    for (const i in data) {
      if (Object.prototype.hasOwnProperty.call(data, i) && data[i].length) {
        url += this.getQuestionOrAmpersand(url);
        url += i + '=' + data[i].toString();
      }
    }

    return this.getEncodedUrlString(url);
  },
  getQuestionOrAmpersand (string) {
    if (string.indexOf('?') === -1) {
      return '?';
    }

    return (string.slice(-1) !== '?' ? '&' : '');
  },
  getQueryParam (queryParam) {
    return new URLSearchParams(window.location.search).get(queryParam);
  },
  getUrlParams () {
    return new URLSearchParams(window.location.search.substring(1));
  },
  getEncodedUrlString (str) {
    return encodeURI(str);
  }
}
