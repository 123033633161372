/**
 * Field helper
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import objectHelper from '@/helpers/object'
import dateHelper from '@/helpers/date'

export default {
  getDefaultProduct (products, productCode) {
    return products.find((product) => product.code === productCode);
  },
  getFieldSeasonByYear (field, year) {
    const season = field.seasons.find(season => season.season === year);
    return typeof season === 'undefined' ? {} : season;
  },
  getFieldProperty (property, data, locale) {
    let value = objectHelper.get(property.key, data)

    // Return value when value matches one of these conditions
    if (!value || typeof value === 'undefined' || typeof value === 'boolean' || Array.isArray(value)) return value;

    if (property.type) {
      switch (property.type) {
      case 'area_in_ha':
        value = value.toString().replace('.', ',');
        break;
      case 'timestamp':
        value = dateHelper.convertTimestamp(value, locale)
        break;
      case 'date':
        value = dateHelper.toFullDate(value, locale)
        break;
      }
    }

    return `${value}` + (property.unit ? ` ${property.unit}` : '');
  },
  hasProductColormap (product, scale) {
    return product.meta.colormaps.includes(scale);
  },
  seasonDataAvailable (field, year) {
    return this.hasSeasons(field, year) && this.hasStatisticsForSeason(field, year);
  },
  hasSeasons (field, year) {
    return field.seasons.findIndex(s => s.season === Number(year)) !== -1;
  },
  hasStatisticsForSeason (field, year) {
    return field.has_statistics_for_season.indexOf(year) !== -1;
  },
  hasStatistics (field) {
    if (!field) return false;
    return !!field.has_statistics_for_season.length
  },
  hasSeasonCropData (field, year) {
    if (!field) return false;

    const seasons = Array.isArray(field.seasons) ? field.seasons : [];
    const season = seasons.find(season => season.season === year);

    if (typeof season === 'undefined') return false;

    const crop = season.crop_name || null;
    const cropVariety = season.crop_variety_name || null;
    const plantingDate = season.planting_date || null;

    return !!(crop && cropVariety && plantingDate);
  },
  isEditableField (field) {
    return field && field.is_editable;
  }
}
