import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const UserRepository = RepositoryFactory.get('user');

const state = {
  authenticated: false,
  user: null
}

const actions = {
  update ({ commit }, data) {
    return new Promise((resolve, reject) => {
      return UserRepository.update(data).then(response => {
        const profile = response.data.data;
        commit('UPDATE_USER_PROFILE', profile);
        resolve(profile);
      }).catch((response) => {
        reject(response);
      });
    })
  },
  get ({ commit }, user) {
    return new Promise((resolve, reject) => {
      return UserRepository.login(user).then(response => {
        const user = response.data.data;
        commit('SET_AUTHENTICATED', true);
        commit('SET_USER', user);
        resolve(user);
      }).catch((error) => {
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', null);
        reject(error);
      });
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_AUTHENTICATED', false);
      commit('SET_USER', null);
      resolve(null);
    });
  }
}

const mutations = {
  SET_USER (state, user) {
    state.user = user;
  },
  SET_AUTHENTICATED (state, isAuthenticated) {
    state.authenticated = isAuthenticated;
  },
  UPDATE_USER_PROFILE (state, profile) {
    state.user.profile = { ...state.user.profile, ...profile };
  }
}

const getters = {
  isAuthenticated (state) {
    return state.authenticated && typeof state.user === 'object';
  },
  userId (state) {
    const user = state.user;
    return (user && typeof user === 'object' ? user.id : null);
  },
  user (state) {
    return state.user
  },
  isBetaUser (state) {
    return (!!(state.user && state.user.is_beta));
  },
  getGps (state) {
    const user = (state.user && state.user.profile ? state.user.profile : {});
    const settings = user.app_settings || {};

    return settings.show_gps || false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
