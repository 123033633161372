/**
 *
 * Constants
 *  Applications constants...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import BENCHMARK from './benchmark';
import CAROUSEL from './carousel';
import CONFIGURATION from './configuration';
import DRAW from './draw';
import EVENTS from './events';
import FIELDS from './fields';
import GLOBAL from './global';
import GRAPHS from './graphs';
import ICONS from './icons';
import IRRIGATIONS from './irrigations';
import LINK from './link';
import LOCALE from './locale';
import MAPS from './maps';
import NAVIGATION from './navigation';
import NOTES from './notes';
import NOTIFICATIONS from './notification';
import PRODUCT from './product';
import SENTRY from './sentry';
import SHOP from './shop';
import STYLEGUIDE from './styleguide';
import VIEWS from './views';
import VUEX from './vuex';
import ZOOM_LEVELS from './zoomLevels';

export default {
  BENCHMARK,
  CAROUSEL,
  CONFIGURATION,
  DRAW,
  EVENTS,
  FIELDS,
  GLOBAL,
  GRAPHS,
  ICONS,
  IRRIGATIONS,
  LINK,
  LOCALE,
  MAPS,
  NAVIGATION,
  NOTIFICATIONS,
  PRODUCT,
  SENTRY,
  NOTES,
  SHOP,
  STYLEGUIDE,
  VIEWS,
  VUEX,
  ZOOM_LEVELS
};
