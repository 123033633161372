/**
 * Routing
 *  Service to handle routing...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import store from '@/store/_store';

export default {

  /*
   * updateRouteStore()
   *  Updates store with new route and sets necessary views...
   *
   * @param   router  Vue Router instance
   *
  **/

  updateRouteStore (router) {
    router.afterEach((to, from) => {
      if (to.meta && to.meta.view && typeof to.meta.view.navigation === 'string') {
        store.dispatch('route/setNavigation', to.meta.view.navigation)
      }

      if (to.meta && to.meta.view && typeof to.meta.view.panel === 'string') {
        store.dispatch('route/setPanel', to.meta.view.panel)
      }

      store.dispatch('route/update', to);
    });
  },

  /*
   * alterBrowserData()
   *  Method to change the browser data...
   *
   * @param   router  Vue Router instance
   *
  **/

  alterDocumentTitle (router) {
    router.beforeEach((to, from, next) => {
      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

      if (typeof nearestWithTitle === 'object' && (nearestWithTitle.meta.title && nearestWithTitle.meta.title.length)) {
        document.title = nearestWithTitle.meta.title;
      }

      next();
    });
  },

  /*
   * isAuthorized()
   *  Method handle routes and authentication...
   *
   * @param   router  Vue Router instance
   *
  **/

  isAuthorized (router) {
    const requiresAuth = (record) => {
      return (typeof record.meta.requiresAuth !== 'undefined' && record.meta.requiresAuth);
    }

    router.beforeEach((to, from, next) => {
      if (to.matched.some(requiresAuth)) {
        if (store.getters['users/isAuthenticated']) {
          next()
        } else {
          next('/users/login')
        }
      } else {
        // When user is authenticated and navigating to one of the pages
        //  defined in 'redirectOn' then redirect user to '/dashboard' page...
        const redirectOn = ['home', 'users.login'];

        if (store.getters['users/isAuthenticated'] && redirectOn.indexOf(to.name) !== -1) {
          next('/dashboard');
          return;
        }

        next();
      }
    })
  },

  /*
   * disableRoutes()
   *  Method to disable routes...
   *
   * @param   router  Vue Router instance
   *
  **/

  disableRoutes (router) {
    const inactive = (record) => {
      return (typeof record.meta.isActive !== 'undefined' && !record.meta.isActive);
    }

    router.beforeEach((to, from, next) => {
      if (to.matched.some(inactive)) {
        window.location = '/404'
      } else {
        next()
      }
    })
  }
}
