import { createStore } from 'vuex-extensions'

import Vue from 'vue'
import Vuex from 'vuex'

import VUEX from '@/constants/vuex';

import Global from '@/store/global'
import Benchmark from '@/store/benchmark'
import Fields from '@/store/fields'
import Graphs from '@/store/graphs'
import InteractiveList from '@/store/interactiveList'
import Irrigation from '@/store/irrigation'
import Map from '@/store/map'
import Notes from '@/store/notes'
import Route from '@/store/route'
import Shop from '@/store/shop'
import Subscriptions from '@/store/subscriptions'
import Ui from '@/store/ui'
import Users from '@/store/users'

Vue.use(Vuex);

const MODULES = VUEX.MODULES;

export default createStore(Vuex.Store, {
  ...Global,
  modules: {
    [MODULES.BENCHMARK]: Benchmark,
    [MODULES.GRAPHS]: Graphs,
    [MODULES.INTERACTIVE_LIST]: InteractiveList,
    [MODULES.NOTES]: Notes,
    [MODULES.FIELDS]: Fields,
    [MODULES.IRRIGATION]: Irrigation,
    [MODULES.MAP]: Map,
    [MODULES.ROUTE]: Route,
    [MODULES.SHOP]: Shop,
    [MODULES.SUBSCRIPTIONS]: Subscriptions,
    [MODULES.UI]: Ui,
    [MODULES.USERS]: Users
  }
})
