/**
 * Home
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import i18n from '@/services/localization';
import config from '@/services/config';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */'@/pages/Dashboard')

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    redirect: '/dashboard/fields',
    meta: {
      title: i18n.t('browser_title.dashboard'),
      gtm: i18n.t('browser_title.dashboard'),
      requiresAuth: true,
      detectScreenSize: true
    },
    children: [
      {
        path: '/dashboard/notes/:noteId?',
        name: 'dashboard.notes',
        meta: {
          title: i18n.t('browser_title.dashboard_notes'),
          gtm: i18n.t('browser_title.dashboard_notes'),
          requiresAuth: true,
          detectScreenSize: true,
          view: {
            navigation: config.get('VIEWS.NAVIGATION.NOTES')
          }
        }
      },
      {
        path: '/dashboard/info',
        name: 'dashboard.info',
        meta: {
          title: i18n.t('browser_title.dashboard_info'),
          gtm: i18n.t('browser_title.dashboard_info'),
          requiresAuth: true,
          detectScreenSize: true,
          view: {
            navigation: config.get('VIEWS.NAVIGATION.INFO')
          }
        }
      },
      {
        path: '/dashboard/configuration',
        name: 'dashboard.configuration',
        meta: {
          title: i18n.t('browser_title.dashboard_configuration'),
          gtm: i18n.t('browser_title.dashboard_configuration'),
          requiresAuth: true,
          detectScreenSize: true,
          view: {
            navigation: config.get('VIEWS.NAVIGATION.CONFIGURATION')
          }
        }
      },
      {
        path: '/dashboard/shop',
        name: 'dashboard.shop',
        meta: {
          title: i18n.t('browser_title.dashboard_shop'),
          gtm: i18n.t('browser_title.dashboard_shop'),
          detectScreenSize: true,
          requiresAuth: true,
          view: {
            navigation: false,
            panel: config.get('VIEWS.PANEL.SHOP.INDEX')
          }
        },
        children: [
          {
            path: '/dashboard/shop/subscription/:subscriptionId',
            name: 'dashboard.shop.subscription.index',
            meta: {
              section: 'dashboard.shop',
              title: i18n.t('browser_title.dashboard_shop'),
              gtm: i18n.t('browser_title.dashboard_shop'),
              detectScreenSize: true,
              requiresAuth: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.SHOP.SUBSCRIPTION.INDEX')
              }
            }
          },
          {
            path: '/dashboard/shop/subscription/:subscriptionId/complete',
            name: 'dashboard.shop.subscription.complete',
            meta: {
              section: 'dashboard.shop',
              title: i18n.t('browser_title.dashboard_shop'),
              gtm: i18n.t('browser_title.dashboard_shop'),
              detectScreenSize: true,
              requiresAuth: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.SHOP.SUBSCRIPTION.COMPLETE')
              }
            }
          }
        ]
      },
      {
        path: '/dashboard/fields',
        name: 'dashboard.fields',
        meta: {
          title: i18n.t('browser_title.dashboard_fields'),
          gtm: i18n.t('browser_title.dashboard_fields'),
          requiresAuth: true,
          detectScreenSize: true,
          view: {
            navigation: config.get('VIEWS.NAVIGATION.FIELDS')
          }
        },
        children: [
          {
            path: '/dashboard/fields/add',
            name: 'dashboard.fields.add',
            meta: {
              title: i18n.t('browser_title.dashboard_fields_add'),
              gtm: i18n.t('browser_title.dashboard_fields_add'),
              requiresAuth: true,
              detectScreenSize: true
            }
          },
          {
            path: '/dashboard/fields/draw',
            name: 'dashboard.fields.draw',
            redirect: '/dashboard/fields/draw/new',
            meta: {
              requiresAuth: true,
              detectScreenSize: true
            },
            children: [
              {
                path: '/dashboard/fields/draw/new',
                name: 'dashboard.fields.draw.new',
                meta: {
                  title: i18n.t('browser_title.dashboard_fields_draw_new'),
                  gtm: i18n.t('browser_title.dashboard_fields_draw_new'),
                  requiresAuth: true,
                  detectScreenSize: true
                }
              },
              {
                path: '/dashboard/fields/draw/edit',
                name: 'dashboard.fields.draw.edit',
                meta: {
                  title: i18n.t('browser_title.dashboard_fields_draw_edit'),
                  gtm: i18n.t('browser_title.dashboard_fields_draw_edit'),
                  requiresAuth: true,
                  detectScreenSize: true
                }
              }
            ]
          },
          {
            path: '/dashboard/fields/:fieldId',
            name: 'dashboard.fields.show',
            meta: {
              section: 'dashboard.fields',
              title: i18n.t('browser_title.dashboard_fields_show'),
              gtm: i18n.t('browser_title.dashboard_fields_show'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: config.get('VIEWS.NAVIGATION.FIELDS')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/graphs',
            name: 'dashboard.fields.graphs',
            meta: {
              section: 'dashboard.fields',
              title: i18n.t('browser_title.dashboard_fields_graphs'),
              gtm: i18n.t('browser_title.dashboard_fields_graphs'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.GRAPHS')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/benchmark',
            name: 'dashboard.fields.benchmark',
            meta: {
              title: i18n.t('browser_title.dashboard_fields_benchmark'),
              gtm: i18n.t('browser_title.dashboard_fields_benchmark'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.BENCHMARK.INDEX')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/benchmark/detail',
            name: 'dashboard.fields.benchmark.detail',
            meta: {
              title: i18n.t('browser_title.dashboard_fields_benchmark_detail'),
              gtm: i18n.t('browser_title.dashboard_fields_benchmark_detail'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.BENCHMARK.DETAIL')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/info',
            name: 'dashboard.fields.info',
            meta: {
              section: 'dashboard.fields',
              title: i18n.t('browser_title.dashboard_fields_info'),
              gtm: i18n.t('browser_title.dashboard_fields_info'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.FIELD.INDEX')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/info/edit',
            name: 'dashboard.fields.info.edit',
            meta: {
              section: 'dashboard.fields',
              title: i18n.t('browser_title.dashboard_fields_info_edit'),
              gtm: i18n.t('browser_title.dashboard_fields_info_edit'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.FIELD.EDIT')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/irrigations',
            name: 'dashboard.fields.irrigations',
            meta: {
              section: 'dashboard.fields',
              title: i18n.t('browser_title.dashboard_fields_irrigations'),
              gtm: i18n.t('browser_title.dashboard_fields_irrigations'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.IRRIGATIONS')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/irrigations/create',
            name: 'dashboard.fields.irrigations.create',
            meta: {
              section: 'dashboard.fields',
              title: i18n.t('browser_title.dashboard_fields_irrigations_create'),
              gtm: i18n.t('browser_title.dashboard_fields_irrigations_create'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.IRRIGATIONS')
              }
            }
          },
          {
            path: '/dashboard/fields/:fieldId/irrigations/edit/:irrigationId',
            name: 'dashboard.fields.irrigations.edit',
            meta: {
              section: 'dashboard.fields',
              title: i18n.t('browser_title.dashboard_fields_irrigations_edit'),
              gtm: i18n.t('browser_title.dashboard_fields_irrigations_edit'),
              requiresAuth: true,
              detectScreenSize: true,
              view: {
                navigation: false,
                panel: config.get('VIEWS.PANEL.FIELD.IRRIGATIONS')
              }
            }
          }
        ]
      }
    ]
  }
]
