/**
 *
 * Benchmark
 *  Benchmark constants...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import i18n from '@/services/localization';

const COLOR_TEXT = '#0C4553';
const COLOR_GRID = '#7DC2DC';
const DEFAULT_PRODUCT = 'p2';

const PRODUCT_DROPDOWN = [
  'p2',
  'p5'
]

const CHART_OPTIONS = {
  spanGaps: true,
  elements: {
    point: {
      radius: 0
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      display: true,
      labels: {
        filter (item) {
          switch (item.text) {
          case 'min':
          case 'max':
            return false;
          case 'mean':
            return Object.assign(item, {
              text: i18n.t('panel.benchmark.detail.legend_mean')
            });
          default:
            return item
          }
        }
      }
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
      caretPadding: 8,
      titleFont: {
        family: 'Open Sans',
        weight: 'normal',
        size: 12
      },
      bodyFont: {
        family: 'Open Sans',
        weight: 'normal',
        size: 12
      },
      multiKeyBackground: 'rgba(0,0,0,0)',
      backgroundColor: 'rgb(41, 41, 41)',
      padding: {
        top: 12,
        left: 10,
        bottom: 12,
        right: 10
      },
      titleSpacing: 6,
      titleMarginBottom: 8,
      callbacks: {
        title (context) {
          const labelName = i18n.t('general.day');
          const label = context[0].label

          return `${labelName} ${label}`;
        },
        label (context) {
          const hidden = ['min', 'max'];

          if (hidden.indexOf(context.dataset.label) !== -1) return null;

          const label = (context.dataset.label === 'mean' ? i18n.t('general.mean') : context.dataset.label);

          return `${label}: ${context.formattedValue}`;
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        color: COLOR_GRID,
        borderColor: COLOR_GRID
      },
      ticks: {
        font: {
          family: 'Open Sans'
        },
        color: COLOR_TEXT
      },
      title: {
        display: true,
        align: 'start',
        text: i18n.t('panel.benchmark.detail.x_axis_label'),
        color: COLOR_TEXT
      }
    },
    y: {
      min: 0,
      max: 1,
      grid: {
        color: COLOR_GRID,
        borderColor: COLOR_GRID
      },
      ticks: {
        font: {
          family: 'Open Sans'
        },
        color: COLOR_TEXT,
        stepSize: 0.1
      },
      title: {
        display: true,
        align: 'start',
        text: null,
        color: COLOR_TEXT
      }
    }
  }
}

export default {
  DEFAULT_PRODUCT,
  PRODUCT_DROPDOWN,
  CHART_OPTIONS
}
