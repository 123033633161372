/**
 * Routes
 *  All application route configurations
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import Router from 'vue-router'
import RouteService from '@/services/route';

import Dashboard from '@/routes/dashboard';
import Default from '@/routes/default';
import Home from '@/routes/home';
import Styleguide from '@/routes/styleguide';
import Users from '@/routes/users';

// Routes
const Routes = [
  ...Dashboard,
  ...Default,
  ...Home,
  ...Styleguide,
  ...Users
];

// Router instance
const vueRouter = new Router({
  mode: 'history',
  routes: Routes
});

// --
// Manipulate routes and/or navigation guards
// --
RouteService.updateRouteStore(vueRouter);
RouteService.isAuthorized(vueRouter);
RouteService.disableRoutes(vueRouter);
RouteService.alterDocumentTitle(vueRouter);

// Export Vue Router
export default vueRouter;
