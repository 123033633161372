/**
 *
 * Maps
 *
 * Maps constants
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import guid from '@/helpers/guid';

const API_KEY = process.env.VUE_APP_MAPS_API_KEY || '';

const LIBRARIES = [
  'drawing',
  'geometry'
]

const GOOGLE_LOGO_SELECTOR = '.gm-style div div img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5_hdpi.png"]'

const TILES_ZOOM_LEVEL = {
  minZoom: 13,
  maxZoom: 19
}

const DEFAULT_MAP_CONFIG = {
  scaleControl: true,
  disableDefaultUI: true,
  streetViewControl: false,
  mapTypeControl: false,
  rotateControl: false,
  fullscreenControl: false,
  zoomControl: false,
  gestureHandling: 'greedy',
  tilt: 0
};

const DEFAULT_MAP_TYPE_ID = 'HYBRID';

const DEFAULT_MAP_STYLES = [
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road',
    stylers: [{ visibility: 'on' }]
  }
];

const MAP_FIELD_STYLES_IDLE = {
  strokeColor: 'white',
  strokeWeight: 1,
  fillColor: 'transparent',
  clickable: false
}

const MAP_FIELD_STYLES_ACTIVE = {
  strokeColor: 'white',
  strokeWeight: 3,
  fillColor: 'transparent',
  clickable: false
}

const MAP_FIELD_STYLES_DRAW_POLYGON = {
  editable: true,
  suppressUndo: true,
  strokeColor: '#f3bb26',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: 'transparent',
  fillOpacity: 0
}

const DEFAULT_CENTER = {
  lng: 5.7480821,
  lat: 52.5001698
}

const DEFAULT_FEATURE_COLLECTION = {
  type: 'FeatureCollection',
  features: []
}

const DEFAULT_MAP_TILE_SIZE = 256;

const DEFAULT_MARKER_TYPES = {
  USER_LOCATION: 'user',
  NEW_NOTE: 'new-note',
  NOTES: 'notes'
}

const USER_MARKER_GUID = guid();

const USER_MARKER_ICON = {
  url: '/image/maps/marker-user.svg'
};

const USER_MARKER_SIZE = {
  size: {
    x: 32,
    y: 32
  },
  origin: {
    x: 0,
    y: 0
  },
  anchor: {
    x: 32 / 2,
    y: 32 / 2
  }
}

const NEW_NOTE_MARKER_ICON = {
  url: '/image/maps/marker-new-note.svg'
};

const NEW_NOTE_MARKER_SIZE = {
  size: {
    x: 36,
    y: 36
  },
  origin: {
    x: 0,
    y: 0
  },
  anchor: {
    x: 36 / 2,
    y: 36 / 2
  }
}
const NOTE_MARKER_ICON = {
  url: '/image/maps/marker-note.svg'
};

const NOTE_MARKER_SIZE = {
  size: {
    x: 36,
    y: 36
  },
  origin: {
    x: 0,
    y: 0
  },
  anchor: {
    x: 36 / 2,
    y: 36 / 2
  }
}

export default {
  API_KEY,
  LIBRARIES,
  GOOGLE_LOGO_SELECTOR,
  TILES_ZOOM_LEVEL,
  MAP_FIELD_STYLES_IDLE,
  MAP_FIELD_STYLES_ACTIVE,
  MAP_FIELD_STYLES_DRAW_POLYGON,
  DEFAULT_MAP_CONFIG,
  DEFAULT_MAP_TYPE_ID,
  DEFAULT_MAP_STYLES,
  DEFAULT_CENTER,
  DEFAULT_FEATURE_COLLECTION,
  DEFAULT_MAP_TILE_SIZE,
  DEFAULT_MARKER_TYPES,
  USER_MARKER_GUID,
  USER_MARKER_ICON,
  USER_MARKER_SIZE,
  NEW_NOTE_MARKER_ICON,
  NEW_NOTE_MARKER_SIZE,
  NOTE_MARKER_ICON,
  NOTE_MARKER_SIZE
}
