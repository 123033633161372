/**
 *
 * Draw
 *  All constants for drawing on Google Maps...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const MODES = {
  CREATE: 'CREATE_FIELD',
  EDIT: 'EDIT_FIELD'
};

export default {
  MODES
};
