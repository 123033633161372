import Api from '@/services/api'

export default {
  login () {
    const resource = '/user'
    return Api().get(`${resource}`);
  },
  create (data) {
    const resource = '/users'
    return Api().post(`${resource}`, JSON.stringify(data));
  },
  update (data) {
    const resource = '/user/profile'
    return Api().put(`${resource}`, data);
  },
  requestPasswordToken (data) {
    const resource = '/users/reset-password'
    return Api().post(`${resource}`, data);
  },
  storePassword (data, token) {
    const resource = `/users/reset-password/${token}`
    return Api().post(`${resource}`, data);
  }
}
