const message = `Concept, ontwerp & realisatie:

██╗   ██╗███████╗███████╗██╗      █████╗ ██████╗ 
██║   ██║██╔════╝██╔════╝██║     ██╔══██╗██╔══██╗
██║   ██║███████╗█████╗  ██║     ███████║██████╔╝
██║   ██║╚════██║██╔══╝  ██║     ██╔══██║██╔══██╗
╚██████╔╝███████║███████╗███████╗██║  ██║██████╔╝
 ╚═════╝ ╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═════╝

 Uselab BV - Keizersgracht 205 - 1016 DS Amsterdam
 Phone +31(0)20 5309666 - Fax +31(0)20 5309667
 E-mail: info@uselab.com - http://www.uselab.com`;

const license = () => {
  if (process.env.NODE_ENV === 'development') return;
  window.console && console.log(message); // eslint-disable-line no-console
}

export default license;
