/**
 * Home
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import i18n from '@/services/localization';

const Default = () => import(/* webpackChunkName: "default" */'@/pages/Default');
const SmallScreen = () => import(/* webpackChunkName: "default" */'@/pages/SmallScreen');

export default [
  {
    path: '/404',
    name: '404',
    component: Default,
    props: {
      image: {
        file: 'error.png'
      },
      title: 'page.page_not_found.title',
      content: 'page.page_not_found.content'
    },
    meta: {
      title: i18n.t('browser_title.page_not_found'),
      gtm: i18n.t('browser_title.page_not_found'),
      detectScreenSize: true
    }
  },
  {
    path: '/500',
    name: '500',
    component: Default,
    props: {
      image: {
        file: 'error.png'
      },
      title: 'page.server_error.title',
      content: 'page.server_error.content'
    },
    meta: {
      title: i18n.t('browser_title.server_error'),
      gtm: i18n.t('browser_title.server_error'),
      detectScreenSize: true
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'default.terms-and-conditons',
    component: Default,
    props: {
      image: {
        file: 'notes.png'
      },
      title: 'page.terms_and_conditions.title',
      content: 'page.terms_and_conditions.content'
    },
    meta: {
      title: i18n.t('browser_title.server_error'),
      gtm: i18n.t('browser_title.server_error'),
      detectScreenSize: true
    }
  },
  {
    path: '/small-screen',
    name: 'default.small-screen',
    component: SmallScreen,
    props: {
      image: {
        file: 'bigger-screen.png',
        alt: 'bigger-screen'
      },
      title: 'page.small_screen_detected.title',
      content: 'page.small_screen_detected.content'
    },
    meta: {
      title: i18n.t('browser_title.server_error'),
      gtm: i18n.t('browser_title.server_error'),
      detectScreenSize: true
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]
