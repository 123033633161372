import Api from '@/services/api'

export default {
  setup (data) {
    const resource = '/shop/setup-subscription';
    return Api().post(`${resource}`, data);
  },
  addFieldsToSubscription (subscriptionId, data) {
    return Api().post(`/subscriptions/${subscriptionId}/fields`, data);
  },
  get () {
    const resource = '/subscriptions'
    return Api().get(`${resource}`);
  }
}
