<template>
  <div>
    <detect-screen-size></detect-screen-size>
    <spinner :guid="spinner.guid" :alignment="spinner.alignment"></spinner>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import LanguageSwitch from '@/mixin/LanguageSwitch'

export default {
  name: 'App',
  mixins: [LanguageSwitch],
  data () {
    return {
      spinner: {
        guid: this.$config.get('GLOBAL.SPINNER_GUID'),
        alignment: 'fixed'
      }
    }
  }
};
</script>
