/**
 * Shop helper
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import SHOP from '@/constants/shop';

export default {
  isSinglePurchaseProduct (inventory, productKey) {
    const SINGLE_PURCHASE = SHOP.PRODUCT_TYPE.SINGLE_PURCHASE;
    const products = this.getProductsGroup(inventory, productKey);
    const product = products.length ? products[0] : {};

    return !!(Object.keys(product).length && product.type === SINGLE_PURCHASE)
  },
  getProductsGroup (inventory, productKey) {
    return inventory.filter(item => item.product.indexOf(productKey) !== -1);
  },
  getProductByKey (inventory, productKey) {
    return inventory.find(item => item.product === productKey);
  },
  getProductKey (inventoryProductKey) {
    const pieces = inventoryProductKey.split('-')

    pieces.pop()

    return pieces.join('-')
  },
  getLocalizationProductSlug (product) {
    return (product || '').replace('-', '_');
  },
  getProductSlug (product) {
    return (product || '').replace('_', '-');
  }
}
