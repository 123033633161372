/**
 * Object helper
 *
 * Generic helper methods for Object
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {

  /**
   * isObject()
   *  Check if value is object
   *
   * @param {object} value value
   */

  isObject (value) {
    return typeof value === 'object';
  },

  /**
     * isEmpty()
     *  Check if object is empty / has keys
     *
     * @param {object} obj object to test
     */

  isEmpty (obj) {
    return typeof obj !== 'object' || Object.keys(obj).length === 0;
  },

  /**
     * findWhere()
     *  Find occurrence in an Array based on criteria
     *
     * @param {array} array to search
     * @param {object} criteria search object
     */

  findWhere (array, criteria) {
    return array.find(item => Object.keys(criteria).every(key => item[key] === criteria[key]))
  },

  /**
     * findIndex()
     *  Find index of item in an Array
     *
     * @param {array} array to search
     * @param {key} key of item
     * @param {value} value of item
     */

  findIndex (array, key, value) {
    return array.map(item => item[key]).indexOf(value);
  },

  /**
     * mergeDeep()
     *  Merge object (deep)
     *
     * @param {object} objects to merge
     */

  mergeDeep (...objects) {
    const isObject = obj => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach(key => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        } else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = this.mergeDeep(pVal, oVal);
        } else {
          prev[key] = oVal;
        }
      });

      return prev;
    }, {});
  },

  /**
     * get()
     *  Get item from object based on string, eg. 'item.nested.key'
     *
     * @param {string} prop to find and return
     * @param {object} object to search
     */

  get (prop, object) {
    if (!object) {
      return null
    }

    if (typeof prop === 'undefined' || !prop.length) {
      return object;
    }

    let p;
    let o = object;
    const props = prop.split('.');

    while (props.length) {
      p = props.shift();
      o = o[p];

      if (!o || typeof o === 'undefined') {
        break;
      }
    }

    return o;
  }
}
