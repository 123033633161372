/**
 * Home
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import i18n from '@/services/localization';
import Home from '@/pages/Home'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: i18n.t('browser_title.homepage'),
      gtm: i18n.t('browser_title.homepage'),
      detectScreenSize: false
    }
  }
]
