/**
 * Array helper
 *
 * Helper methods for Array's
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {

  /**
   * isArray()
   *  Object is instance of [object Array]
   *
   * @param {object} obj object/array
   *
   */

  isArray (obj) {
    return Object.prototype.toString.call(obj) === '[object Array]'
  },

  /**
   * sort()
   *  Sort an array of objects based on key
   *
   * @param {array} data
   * @param {string} key
   *
   */

  sort (data, key) {
    return data.sort((a, b) => a[key] - b[key]);
  }
}
