/**
 *
 * Events
 *  All constants for events...
 *
 * @author     K. Galstaun <k.galstaun@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {
  // Center to user marker
  CENTER_TO_USER_LOCATION: 'center-user-location',
  // Toggle notes
  TOGGLE_NOTES: 'toggle-notes',
  // Center to marker
  CENTER_TO_MARKER: 'center-marker',
  // fields (values) have been updated
  UPDATE_FIELDS: 'update-fields',
  // spinner show or hide toggle
  TOGGLE_SPINNER: 'toggle-spinner',
  // discard edit mode
  DISCARD_EDIT_MODE: 'discard-edit-mode'
};
