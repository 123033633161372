/**
 * Api
 *  Service to handle API calls through axios...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import axios from 'axios'
import config from './config';
import store from '@/store/_store';
import storage from '@/helpers/storage';

export default () => {
  const auth = storage.getAuth();
  const locale = storage.getLocale();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': locale
  }

  if (auth && typeof auth === 'object') {
    headers.Authorization = `Bearer ${auth.access_token}`
  }

  const api = axios.create({
    baseURL: config.get('GLOBAL.API_URL'),
    headers
  });

  api.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const response = typeof error.response === 'object' ? error.response : {};

    // Logout and redirect user when action is forbidden...
    if (response.status && response.status === 403) {
      storage.removeAuth();
      store.dispatch('users/logout');
      window.location.href = '/';
    } else {
      return Promise.reject(response);
    }
  });

  return api;
}
