/**
 * Localforage
 *  Service to handle local storage in browser
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import localforage from 'localforage';
import config from './config';

export default localforage.createInstance({
  name: config.get('GLOBAL.LOCAL_STORAGE'),
  driver: localforage.LOCALSTORAGE
})
