/**
 *
 * Icons
 *  All constants for icons...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const ACCOUNT_WHITE = 'account-white'
const ADD = 'add'
const ADD_DISABLED = 'add-disabled';
const ARROW_DOWN_BLUE = 'arrow-down-blue'
const ARROW_DOWN_WHITE = 'arrow-down-white'
const ARROW_LEFT_BLUE = 'arrow-left-blue'
const ARROW_LEFT_WHITE = 'arrow-left-white'
const ARROW_RIGHT_BLUE = 'arrow-right-blue'
const ARROW_RIGHT_BLUE_LIGHT = 'arrow-right-blue-light'
const ARROW_RIGHT_WHITE = 'arrow-right-white'
const ARROW_UP_WHITE = 'arrow-up-white'
const ATTACHMENT = 'attachment'
const CLOSE = 'close'
const COLORMAP_ABSOLUTE_WHITE = 'colormap-absolute-white'
const COLORMAP_RELATIVE_WHITE = 'colormap-relative-white'
const DATEPICKER_ACTIVE = 'datepicker-active'
const DELETE = 'delete'
const DONE = 'done'
const DRAW = 'draw';
const DRAW_BRP_RESET = 'draw-brp-reset';
const DRAW_DISABLED = 'draw-disabled';
const DRAW_INFO = 'draw-info';
const DRAW_NEW = 'draw-new';
const DRAW_UNDO = 'draw-undo';
const DRAW_WHITE = 'draw-white';
const DROPDOWN_TOGGLE = 'dropdown-toggle';
const EDIT = 'edit'
const EDIT_DOT = 'edit-dot'
const FIELDSCOUT_LOGO = 'fieldscout-logo';
const INFO = 'info'
const INFO_WARNING = 'info-warning';
const INFO_WHITE = 'info-white'
const IRRIGATIONS = 'irrigations';
const LANGUAGE = 'language';
const LEGENDA_ADD_POINT = 'legenda-add-point';
const LEGENDA_CLOSE_SHAPE = 'legenda-close-shape';
const LEGENDA_MOVE_POINT = 'legenda-move-point';
const LEGENDA_REMOVE_POINT = 'legenda-remove-point';
const MAP_OPTIONS_TOGGLE = 'map-options-toggle'
const MAP_OPTIONS_USER_LOCATION = 'map-options-user-location'
const NAV_COLLAPSE = 'nav-collapse'
const NAV_CONFIGURATION = 'nav-configuration';
const NAV_EXPAND = 'nav-expand';
const NAV_FIELDS = 'nav-fields';
const NAV_INFO = 'nav-info';
const NAV_NOTES = 'nav-notes';
const NAV_SHOP = 'nav-shop';
const NO_DATA = 'no-data';
const NOTE = 'note'
const NOTES_HIDE = 'notes-hide'
const NOTES_SHOW = 'notes-show'
const NUMBERED_BULLET_1 = 'numbered-bullet-1'
const NUMBERED_BULLET_2 = 'numbered-bullet-2'
const NUMBERED_BULLET_3 = 'numbered-bullet-3'
const NUMBERED_BULLET_4 = 'numbered-bullet-4'
const NUMBERED_BULLET_5 = 'numbered-bullet-5'
const NUMBERED_BULLET_6 = 'numbered-bullet-6'
const NUMBERED_BULLET_7 = 'numbered-bullet-7'
const NUMBERED_BULLET_8 = 'numbered-bullet-8'
const NUMBERED_BULLET_9 = 'numbered-bullet-9'
const NUMBERED_BULLET_9_PLUS = 'numbered-bullet-9plus'
const PARCEL = 'parcel'
const PARCEL_DATA_ACTIVE = 'parcel-data-active';
const PARCEL_DATA_IDLE = 'parcel-data-idle';
const PARCEL_MAP = 'parcel-map';
const SHOP = 'shop'
const SHOP_ORDER = 'shop-order';
const STATUS_PENDING = 'status-pending'
const STATUS_SUCCESS = 'icon-status-success';
const STATUS_UNAVAILABLE = 'status-unavailable'
const TAB_BENCHMARK = 'tab-benchmark'
const TAB_GRAPHS = 'tab-graphs'
const TAB_INFO = 'tab-info'
const TAB_IRRIGATIONS = 'tab-irrigations'

export default {
  ACCOUNT_WHITE,
  ADD,
  ADD_DISABLED,
  ARROW_DOWN_BLUE,
  ARROW_DOWN_WHITE,
  ARROW_LEFT_BLUE,
  ARROW_LEFT_WHITE,
  ARROW_RIGHT_BLUE,
  ARROW_RIGHT_BLUE_LIGHT,
  ARROW_RIGHT_WHITE,
  ARROW_UP_WHITE,
  ATTACHMENT,
  CLOSE,
  COLORMAP_ABSOLUTE_WHITE,
  COLORMAP_RELATIVE_WHITE,
  DATEPICKER_ACTIVE,
  DELETE,
  DONE,
  DRAW,
  DRAW_BRP_RESET,
  DRAW_DISABLED,
  DRAW_INFO,
  DRAW_NEW,
  DRAW_UNDO,
  DRAW_WHITE,
  DROPDOWN_TOGGLE,
  EDIT,
  EDIT_DOT,
  FIELDSCOUT_LOGO,
  INFO,
  INFO_WARNING,
  INFO_WHITE,
  IRRIGATIONS,
  LANGUAGE,
  LEGENDA_ADD_POINT,
  LEGENDA_CLOSE_SHAPE,
  LEGENDA_MOVE_POINT,
  LEGENDA_REMOVE_POINT,
  MAP_OPTIONS_TOGGLE,
  MAP_OPTIONS_USER_LOCATION,
  NAV_COLLAPSE,
  NAV_CONFIGURATION,
  NAV_EXPAND,
  NAV_FIELDS,
  NAV_INFO,
  NAV_NOTES,
  NAV_SHOP,
  NO_DATA,
  NOTE,
  NOTES_HIDE,
  NOTES_SHOW,
  NUMBERED_BULLET_1,
  NUMBERED_BULLET_2,
  NUMBERED_BULLET_3,
  NUMBERED_BULLET_4,
  NUMBERED_BULLET_5,
  NUMBERED_BULLET_6,
  NUMBERED_BULLET_7,
  NUMBERED_BULLET_8,
  NUMBERED_BULLET_9,
  NUMBERED_BULLET_9_PLUS,
  PARCEL,
  PARCEL_DATA_ACTIVE,
  PARCEL_DATA_IDLE,
  PARCEL_MAP,
  SHOP,
  SHOP_ORDER,
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_UNAVAILABLE,
  TAB_BENCHMARK,
  TAB_GRAPHS,
  TAB_INFO,
  TAB_IRRIGATIONS
};
