/**
 *
 * Events
 *  All constants for events...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const DEFAULT_PRODUCT_CODE = 'p13';
const PRODUCT_AVAILABLE = 'available';
const PRODUCT_UNAVAILABLE = 'unavailable';
const PRODUCT_PENDING = 'pending';

const INFO_LINK = 'https://bioscope.nl/kaarten/'

export default {
  DEFAULT_PRODUCT_CODE,
  PRODUCT_AVAILABLE,
  PRODUCT_UNAVAILABLE,
  PRODUCT_PENDING,
  INFO_LINK
};
