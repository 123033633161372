/**
 *
 * zoomLevels
 *
 * Zoom level constants
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const DEFAULT_MAP = {
  zoom: 9,
  minZoom: 0,
  maxZoom: 19
}

const FIELD = 17;

const USER_LOCATION = 12;

export default {
  DEFAULT_MAP,
  FIELD,
  USER_LOCATION
}
