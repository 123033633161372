/**
 *
 * Notes
 *  All settings for notes...
 *
 * @author     K. Galstaun <k.galstaun@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {
  ICON: 'note',
  KEY_TITLE: 'title',
  KEY_NOTE: 'note',
  KEY_PHOTO: 'image',
  KEY_FIELD_NAME: 'field_name',
  KEY_LAT: 'y',
  KEY_LNG: 'x',
  KEY_TIMESTAMP: 'created_at'
};
