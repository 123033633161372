/**
 * main.js
 *
 * Application entry point
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import license from './license';

/* -----------------------------------------------------------------
 * application dependencies
 * -------------------------------------------------------------- */

import Vue from 'vue';
import App from './App.vue';
import store from '@/store/_store';
import router from '@/routes/_routes';
import config from '@/services/config';
import localforage from '@/services/localforage';
import i18n from '@/services/localization';
import createSentry from '@/sentry/sentry.js'

/* -----------------------------------------------------------------
 * components/views
 * -------------------------------------------------------------- */
import '@/components/_components.js';
import '@/views/_views.js';

/* -----------------------------------------------------------------
 * vue dependencies
 * -------------------------------------------------------------- */
import VueRouter from 'vue-router';
import VueGtm from '@gtm-support/vue2-gtm';
import { VBTooltip } from 'bootstrap-vue'

/* -----------------------------------------------------------------
 * vue dependency setup
 * -------------------------------------------------------------- */
Vue.use(VueRouter);
Vue.use(VueGtm, {
  id: config.get('GLOBAL.GTM_ID'),
  debug: (process.env.NODE_ENV === 'development'),
  enabled: true,
  loadScript: true,
  vueRouter: router
});
Vue.directive('b-tooltip', VBTooltip)

/* -----------------------------------------------------------------
 * Create Sentry instance
 * -------------------------------------------------------------- */
createSentry(Vue, router);

/* -----------------------------------------------------------------
 * define constants
 * -------------------------------------------------------------- */
const settings = {
  i18n,
  router,
  store,
  render: h => h(App)
};

/* -----------------------------------------------------------------
 * extend vue
 * -------------------------------------------------------------- */
Object.defineProperty(Vue.prototype, '$storage', {
  value: localforage
});

Object.defineProperty(Vue.prototype, '$config', {
  value: config
});

/* -----------------------------------------------------------------
 * initialize
 * -------------------------------------------------------------- */
Vue.config.productionTip = false

store.dispatch('users/get').catch((error) => {
  const response = error.response || {};
  if (process.env.NODE_ENV !== 'development' || (response.status && response.status === 401)) return;
  console.log(response); // eslint-disable-line no-console
}).finally(() => {
  new Vue(settings).$mount('#app');
  window.APP_VERSION = process.env.SENTRY_VERSION || null;
})

/* -----------------------------------------------------------------
 * license
 * -------------------------------------------------------------- */

license();
