/**
 * Sentry
 *  Implementation of Sentry monitoring
 *
 * Important note:
 *  Sentry will only work on a `production` build, which only should be created
 *  when a new release is being created for test/acceptance/production environments...
 *
 * For more information pls visit the Confluence project page.
 */

import { init, BrowserTracing, vueRouterInstrumentation } from '@sentry/vue';

import sentryHelper from './helpers'
import config from './config'

/**
 * createSentry
 *  Creates a new Sentry instance
 *
 * Return initialization for these conditions
 *  Current build isn't a production build (npm task `build-release`)
 *  Current environment is `null` (see config.js)
 *  Current environment is local and not in debug mode
 *
 * @return {boolean} Initialization status
 */

export default function createSentry (Vue, router) {
  const sentryConfig = {
    Vue,
    ...config,
    integrations: [
      new BrowserTracing({
        ...router ? { routingInstrumentation: vueRouterInstrumentation(router) } : {},
        tracePropagationTargets: ['localhost', /^\//]
      })
    ]
  }

  window.APP_SENTRY = {
    initialized: false,
    debug: sentryHelper.isDebug(),
    environment: sentryConfig.environment,
    release: sentryConfig.release
  };

  // Return -> environment is null
  // Return -> environment is development and not in debug mode
  if (sentryConfig.environment === null) return null;
  else if (sentryConfig.environment === 'development' && !sentryHelper.isDebug()) return null;

  init(sentryConfig);

  window.APP_SENTRY.initialized = true;

  if (sentryHelper.isDebug()) {
    setTimeout(() => {
      console.error('setTimeout/sentryConfig', sentryConfig) // eslint-disable-line no-console
      throw new Error('[debug] Sentry Error');
    }, 5000)
  }
}
