/**
 *
 * Sentry
 *  All sentry constants...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const DSN = 'https://bb3c8c6899f440ce8706397435d54696@o281606.ingest.sentry.io/6077661';

const ENVIRONMENTS = {
  development: [
    'fieldscout.bioscope.nl.local',
    'localhost'
  ],
  test: [
    'fieldscout.bioscope.nl.test.uselab.com'
  ],
  acceptance: [
    'acceptatie-fieldscout.bioscope.nl'
  ],
  production: [
    'fieldscout.bioscope.nl'
  ]
};

export default {
  DSN,
  ENVIRONMENTS
}
