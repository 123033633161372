/**
 *
 *  Configuration
 *  All constants for configuration...
 *
 * @author     K. Galstaun <k.galstaun@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {
  KEY_USER_EMAIL: 'email',
  KEY_USER_PHONE: 'profile.phone',
  KEY_USER_SMS_NOTIFICATIONS: 'profile.preferences.sms_notifications',
  KEY_USER_EMAIL_NOTIFICATIONS: 'profile.preferences.email_notifications',
  KEY_GPS: 'profile.app_settings.show_gps',
  KEY_WORKING_WIDTH: 'working_width',
  KEY_SHOW_WORKING_WIDTH: 'show_working_width'
};
