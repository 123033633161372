/**
 *
 * Benchmark
 *  Benchmark constants...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */
import moment from 'moment';
import dateHelper from '@/helpers/date';
import i18n from '@/services/localization';

import LOCALE from './locale';

const GRAPH_TYPES = {
  BIOMASS_AND_NITROGEN: 'biomass_and_nitrogen',
  SOIL_MOISTURE: 'soil_moisture'
}

const CURRENT_LOCALE = i18n.locale.split('-')[0];
const DATE_FORMAT = LOCALE.DATE_FORMAT_SHORT[CURRENT_LOCALE]

const COLOR_TEXT = '#0C4553';
const COLOR_GRID = '#7DC2DC';

const LOCAL_STORAGE = {
  KEY_FEATURE_NOTIFICATION_GRAPHS: 'feature-notification-data'
};

const CHART_OPTIONS = {
  spanGaps: true,
  cubicInterpolationMode: 'monotone',
  responsive: true,
  maintainAspectRatio: false,
  locale: i18n.locale,
  title: {
    display: false
  },
  plugins: {
    datalabels: {
      color: '#36A2EB'
    },
    legend: {
      position: 'bottom',
      display: true,
      labels: {
        color: COLOR_TEXT,
        font: {
          family: 'Open Sans'
        },
        boxWidth: 12
      },
      title: {
        color: COLOR_TEXT,
        font: {
          family: 'Open Sans'
        }
      }
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
      caretPadding: 8,
      titleFont: {
        family: 'Open Sans',
        weight: 'normal',
        size: 12
      },
      bodyFont: {
        family: 'Open Sans',
        weight: 'normal',
        size: 12
      },
      multiKeyBackground: 'rgba(0,0,0,0)',
      backgroundColor: 'rgb(41, 41, 41)',
      padding: {
        top: 12,
        left: 10,
        bottom: 12,
        right: 10
      },
      titleSpacing: 6,
      titleMarginBottom: 8,
      callbacks: {
        title (context) {
          const date = dateHelper.getDate(context[0].label);

          return moment(date).format(DATE_FORMAT);
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        stepSize: 7,
        displayFormats: {
          day: DATE_FORMAT
        }
      },
      display: true,
      grid: {
        color: COLOR_GRID,
        borderColor: COLOR_GRID
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45,
        callback: function (val, index) {
          // Hide the label of every 2nd dataset
          return index % 2 === 0 ? val : '';
        },
        font: {
          family: 'Open Sans'
        },
        color: COLOR_TEXT
      },
      title: {
        color: COLOR_TEXT
      }
    },
    p2: {
      type: 'linear',
      display: true,
      position: 'left',
      min: 0,
      max: 1,
      grid: {
        color: COLOR_GRID,
        borderColor: COLOR_GRID
      },
      ticks: {
        font: {
          family: 'Open Sans'
        },
        color: COLOR_TEXT,
        stepSize: 0.1
      },
      title: {
        display: true,
        align: 'start',
        text: i18n.t('field.products.p2'),
        color: COLOR_TEXT
      }
    },
    p5: {
      type: 'linear',
      display: true,
      position: 'right',
      min: 0,
      max: 1,
      grid: {
        color: COLOR_GRID,
        borderColor: COLOR_GRID
      },
      ticks: {
        font: {
          family: 'Open Sans'
        },
        color: COLOR_TEXT,
        stepSize: 0.1
      },
      title: {
        display: true,
        align: 'start',
        text: i18n.t('field.products.p5'),
        color: COLOR_TEXT
      }
    }
  }
}

const CHART_OPTIONS_SOIL_MOISTURE = {
  spanGaps: true,
  cubicInterpolationMode: 'monotone',
  responsive: true,
  maintainAspectRatio: false,
  locale: i18n.locale,
  title: {
    display: false
  },
  plugins: {
    annotation: {
      annotations: {
        today: {
          type: 'line',
          xMin: moment().dayOfYear() - 1,
          xMax: moment().dayOfYear() - 1,
          borderColor: '#0C4553',
          borderWidth: 2
        }
      }
    },
    datalabels: {
      color: '#36A2EB'
    },
    legend: {
      position: 'bottom',
      display: true,
      labels: {
        color: COLOR_TEXT,
        font: {
          family: 'Open Sans'
        },
        boxWidth: 12
      },
      title: {
        color: COLOR_TEXT,
        font: {
          family: 'Open Sans'
        }
      }
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      position: 'nearest',
      caretPadding: 8,
      titleFont: {
        family: 'Open Sans',
        weight: 'normal',
        size: 12
      },
      bodyFont: {
        family: 'Open Sans',
        weight: 'normal',
        size: 12
      },
      multiKeyBackground: 'rgba(0,0,0,0)',
      backgroundColor: 'rgb(41, 41, 41)',
      padding: {
        top: 12,
        left: 10,
        bottom: 12,
        right: 10
      },
      titleSpacing: 6,
      titleMarginBottom: 8,
      callbacks: {
        title (context) {
          return context[0].label;
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    }
  },
  scales: {
    x: {
      display: true,
      grid: {
        color: COLOR_GRID,
        borderColor: COLOR_GRID
      },
      ticks: {
        z: 10,
        maxRotation: 45,
        minRotation: 45,
        font: {
          family: 'Open Sans'
        },
        color: COLOR_TEXT
      },
      title: {
        color: COLOR_TEXT
      }
    },
    y: {
      beginAtZero: true,
      type: 'linear',
      display: true,
      position: 'left',
      title: {
        text: 'mm',
        display: true,
        align: 'start',
        color: COLOR_TEXT
      },
      grid: {
        color: COLOR_GRID,
        borderColor: COLOR_GRID
      },
      ticks: {
        z: 10,
        font: {
          family: 'Open Sans'
        },
        color: COLOR_TEXT
      }
    }
  }
}

export default {
  GRAPH_TYPES,
  LOCAL_STORAGE,
  CHART_OPTIONS,
  CHART_OPTIONS_SOIL_MOISTURE
}
