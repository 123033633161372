/**
 *
 * Navigation
 *
 * @author     K. Galstaun <k.galstaun@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const MENU_ITEMS = [
  {
    key: 'fields',
    name: 'dashboard.fields',
    path: '/dashboard/fields',
    active: false
  },
  {
    key: 'notes',
    name: 'dashboard.notes',
    path: '/dashboard/notes',
    active: false
  },
  {
    key: 'info',
    name: 'dashboard.info',
    path: '/dashboard/info',
    absolute: true,
    active: false
  },
  {
    key: 'configuration',
    name: 'dashboard.configuration',
    path: '/dashboard/configuration',
    absolute: true,
    active: false
  },
  {
    key: 'shop',
    name: 'dashboard.shop',
    path: '/dashboard/shop',
    absolute: true,
    active: false
  },
  {
    key: 'benchmark',
    name: 'dashboard.benchmark',
    path: '/dashboard/benchmark',
    absolute: false,
    hidden: true,
    active: false
  }
];

export default {
  MENU_ITEMS
};
