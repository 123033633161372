import Vue from 'vue';

// Navigation
Vue.component('Configuration', () => import(/* webpackChunkName: "navigation" */ './navigation/Configuration.vue'));
Vue.component('Fields', () => import(/* webpackChunkName: "navigation" */ './navigation/Fields.vue'));
Vue.component('Info', () => import(/* webpackChunkName: "navigation" */ './navigation/Info.vue'));
Vue.component('Notes', () => import(/* webpackChunkName: "navigation" */ './navigation/Notes.vue'));

// Panel:Field
Vue.component('FieldBenchmark', () => import(/* webpackChunkName: "panel" */ './panel/field/FieldBenchmark.vue'))
Vue.component('FieldBenchmarkDetail', () => import(/* webpackChunkName: "panel" */ './panel/field/FieldBenchmarkDetail.vue'))
Vue.component('FieldGraphs', () => import(/* webpackChunkName: "panel" */ './panel/field/FieldGraphs.vue'))
Vue.component('FieldData', () => import(/* webpackChunkName: "panel" */ './panel/field/FieldData.vue'))
Vue.component('FieldDataEdit', () => import(/* webpackChunkName: "panel" */ './panel/field/FieldDataEdit.vue'))
Vue.component('FieldIrrigations', () => import(/* webpackChunkName: "panel" */ './panel/field/FieldIrrigations.vue'))

// Panel:Shop
Vue.component('ShopIndex', () => import(/* webpackChunkName: "panel" */ './panel/shop/ShopIndex.vue'))
Vue.component('ShopOrderIndex', () => import(/* webpackChunkName: "panel" */ './panel/shop/ShopOrderIndex.vue'))
Vue.component('ShopOrderComplete', () => import(/* webpackChunkName: "panel" */ './panel/shop/ShopOrderComplete.vue'))
Vue.component('ShopProduct', () => import(/* webpackChunkName: "panel" */ './panel/shop/ShopProduct.vue'))
Vue.component('ShopSubscriptionIndex', () => import(/* webpackChunkName: "panel" */ './panel/shop/ShopSubscriptionIndex.vue'))
Vue.component('ShopSubscriptionComplete', () => import(/* webpackChunkName: "panel" */ './panel/shop/ShopSubscriptionComplete.vue'))
