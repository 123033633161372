/**
 *
 * Styleguide
 *
 * Styleguide constants
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const COLORS = [
  {
    group: 'palette',
    colors: [
      {
        variable: '$theme-color-primary',
        palette: '$theme-color-green-default'
      },
      {
        variable: '$theme-color-secondary',
        palette: '$theme-color-yellow-default'
      }
    ]
  },
  {
    group: 'green',
    colors: [
      {
        variable: '$theme-color-green-default',
        hexCode: '#4DBD38'
      },
      {
        variable: '$theme-color-green-light',
        hexCode: '#8ED547'
      },
      {
        variable: '$theme-color-green-dark',
        hexCode: '#136C1F'
      }
    ]
  },
  {
    group: 'yellow',
    colors: [
      {
        variable: '$theme-color-yellow-default',
        hexCode: '#F3D526'
      },
      {
        variable: '$theme-color-yellow-dark',
        hexCode: '#F3BB26'
      }
    ]
  },
  {
    group: 'blue',
    colors: [
      {
        variable: '$theme-color-blue-default',
        hexCode: '#289BC6'
      },
      {
        variable: '$theme-color-blue-light',
        hexCode: '#28C6C3'
      },
      {
        variable: '$theme-color-blue-dark',
        hexCode: '#0C4553'
      }
    ]
  },
  {
    group: 'misc',
    colors: [
      {
        variable: '$theme-color-purple',
        hexCode: '#722A7F'
      },
      {
        variable: '$theme-color-orange',
        hexCode: '#FD761E'
      },
      {
        variable: '$theme-color-brown',
        hexCode: '#8E552D'
      }
    ]
  },
  {
    group: 'gray',
    colors: [
      {
        variable: '$theme-color-gray-1',
        hexCode: '#9DA6A9'
      },
      {
        variable: '$theme-color-gray-2',
        hexCode: '#D8D8D8'
      },
      {
        variable: '$theme-color-gray-3',
        hexCode: '#E2E2E2'
      },
      {
        variable: '$theme-color-gray-4',
        hexCode: '#EDEDED'
      }
    ]
  }
]

export default {
  COLORS
};
