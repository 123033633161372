const state = {
  editMode: false,
  activeItem: null
}

const actions = {
  toggleEditMode ({ commit }) {
    commit('TOGGLE_EDIT_MODE')
  },
  resetEditMode ({ commit }) {
    commit('RESET_EDIT_MODE')
  },
  setActiveItem ({ commit }, payload) {
    commit('SET_ACTIVE_ITEM', payload)
  }
}

const mutations = {
  TOGGLE_EDIT_MODE (state) {
    state.editMode = !state.editMode;
  },
  RESET_EDIT_MODE (state) {
    state.editMode = false;
  },
  SET_ACTIVE_ITEM (state, payload) {
    state.activeItem = payload;
  }
}

const getters = {
  editMode (state) {
    return state.editMode
  },
  activeItem (state) {
    return state.activeItem
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
