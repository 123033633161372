import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const IrrigationRepository = RepositoryFactory.get('irrigation');

const state = {
  irrigationAdvices: [],
  fieldIrrigations: []
}

const actions = {
  getAllIrrigationAdvices ({ commit }) {
    return new Promise((resolve, reject) => {
      return IrrigationRepository.getAllIrrigationAdvices().then(response => {
        const irrigationAdvices = response.data.data;
        commit('SET_IRRIGATION_ADVICES', irrigationAdvices);
        resolve(irrigationAdvices)
      }).catch((error) => {
        commit('SET_IRRIGATION_ADVICES', []);
        reject(error);
      });
    });
  },
  getAllFieldIrrigations ({ commit }, fieldId) {
    return new Promise((resolve, reject) => {
      return IrrigationRepository.getFieldIrrigations(fieldId).then(response => {
        const irrigations = response.data.data;
        commit('SET_FIELD_IRRIGATIONS', irrigations);
        resolve(irrigations)
      }).catch((error) => {
        commit('SET_FIELD_IRRIGATIONS', []);
        reject(error);
      });
    })
  },
  getFieldIrrigationsAdvice ({ commit }, fieldId) {
    return new Promise((resolve, reject) => {
      return IrrigationRepository.getFieldIrrigationsAdvice(fieldId).then(response => {
        const advice = response.data.data;
        resolve(advice);
      }).catch(response => {
        reject(response);
      });
    })
  },
  resetFieldIrrigations ({ commit }) {
    commit('SET_FIELD_IRRIGATIONS', []);
  }
}

const mutations = {
  SET_IRRIGATION_ADVICES (state, irrigationAdvices) {
    state.irrigationAdvices = irrigationAdvices;
  },
  SET_FIELD_IRRIGATIONS (state, irrigations) {
    state.fieldIrrigations = irrigations;
  }
}

const getters = {
  getIrrigationAdvices: state => state.irrigationAdvices,
  getFieldIrrigations: state => state.fieldIrrigations,
  getFieldIrrigationById: (state) => (id) => {
    return state.fieldIrrigations.find(irrigation => irrigation.id === id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
