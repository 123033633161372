/**
 *
 * Global
 *
 * Global constants
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default {
  STORAGE_ERROR: 'storage_error'
}
