const state = {
  datepicker: {
    from: null,
    till: null
  }
}

const actions = {
  setDatePickerValues ({ commit }, datepicker) {
    commit('SET_DATEPICKER', datepicker);
  }
}

const mutations = {
  SET_DATEPICKER (state, datepicker) {
    state.datepicker = { ...state.datepicker, ...datepicker };
  }
}

const getters = {
  datePickerFrom (state) {
    return state.datepicker.from
  },
  datePickerTill (state) {
    return state.datepicker.till
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
