const state = {
  bounds: [],
  currentBounds: null,
  zoomLevelChanged: false,
  zoomLevel: null,
  locationBrowserSetting: {
    allowed: false,
    message: ''
  },
  geoConsent: 'IDLE'
}

const actions = {
  geoConsent ({ commit }, data) {
    commit('SET_GEO_CONSENT', data);
  },
  addBounds ({ commit }, bounds) {
    commit('ADD_BOUNDS', bounds);
  },
  setCurrentBounds ({ commit }, id) {
    commit('SET_CURRENT_BOUNDS', id);
  },
  setZoomLevel ({ commit }, zoomLevel) {
    commit('SET_ZOOM_LEVEL', zoomLevel);
  },
  changeZoomLevel ({ commit }, zoomLevel) {
    commit('CHANGE_ZOOM_LEVEL', zoomLevel);
  }
}

const mutations = {
  SET_GEO_CONSENT (state, consent) {
    state.geoConsent = consent;
  },
  SET_CURRENT_BOUNDS (state, id) {
    state.currentBounds = (!id ? null : state.bounds.find(bounds => bounds.id === id));
  },
  ADD_BOUNDS (state, bounds) {
    if (!bounds.id) return;

    const index = state.bounds.findIndex((b) => b.id === bounds.id)

    if (index !== -1) return;

    state.bounds.push(bounds)
  },
  SET_ZOOM_LEVEL (state, zoomLevel) {
    state.zoomLevel = zoomLevel;
  },
  CHANGE_ZOOM_LEVEL (state, zoomLevel) {
    state.zoomLevel = zoomLevel;
    state.zoomLevelChanged = true;
  }
}

const getters = {
  getGeoConsent: state => state.geoConsent,
  getBounds: state => state.currentBounds ? state.currentBounds : null,
  getZoomLevel: state => state.zoomLevel,
  getZoomLevelChanged: state => state.zoomLevelChanged,
  getLocationBrowserSetting: state => state.locationBrowserSetting
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
