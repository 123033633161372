const state = {
  currentRoute: {},
  view: {
    navigation: null,
    panel: null
  }
}

const actions = {
  update ({ commit }, route) {
    commit('UPDATE_ROUTE', route);
  },
  setNavigation ({ commit }, navigation) {
    commit('SET_NAVIGATION', navigation);
  },
  setPanel ({ commit }, panel) {
    commit('SET_PANEL', panel);
  }
}

const mutations = {
  UPDATE_ROUTE (state, route) {
    let navigation = false;
    let panel = false;

    if (route.meta.view) {
      navigation = (!!(typeof route.meta.view.navigation === 'string' || route.meta.view.navigation))
      panel = (!!(typeof route.meta.view.panel === 'string' || route.meta.view.panel));
    }

    state.currentRoute = {
      ...route,
      meta: {
        ...route.meta,
        ...{
          view: {
            navigation,
            panel
          }
        }
      }
    };
  },
  SET_NAVIGATION (state, navigation) {
    state.view.navigation = navigation;
  },
  SET_PANEL (state, panel) {
    state.view.panel = panel;
  }
}

const getters = {
  currentRoute: state => state.currentRoute,
  view: state => state.view
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
