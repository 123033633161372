import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const ShopRepository = RepositoryFactory.get('shop');

const state = {
  inventory: [],
  product: {},
  cart: []
}

const actions = {
  resetCart ({ commit }) {
    commit('RESET_CART');
  },
  addToCart ({ commit }, product) {
    return new Promise((resolve, reject) => {
      commit('ADD_TO_CART', product);
      resolve()
    });
  },
  createShopOrder ({ commit }, data) {
    return new Promise((resolve, reject) => {
      ShopRepository.createShopOrder(data).then(order => resolve(order));
    });
  },
  getShopInventory ({ commit }) {
    return new Promise((resolve, reject) => {
      ShopRepository.getShopInventory().then(response => {
        const inventory = response.data.data;
        commit('SET_INVENTORY', inventory);
        resolve(inventory)
      });
    });
  },
  getShopProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      ShopRepository.getShopProducts().then(products => resolve(products));
    });
  },
  getShopProduct ({ commit }, productKey) {
    ShopRepository.getShopProduct(productKey).then(product => {
      commit('SET_PRODUCT', product);
    })
  }
}

const mutations = {
  RESET_CART (state) {
    state.cart = [];
  },
  ADD_TO_CART (state, product) {
    state.cart.push(product);
  },
  SET_INVENTORY (state, inventory) {
    state.inventory = inventory
  },
  SET_PRODUCT (state, product) {
    state.product = product
  }
}

const getters = {
  inventory: state => state.inventory,
  product: state => state.product,
  cart: state => state.cart
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
