/**
 *
 * Shop
 *  All constants for shop...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const TIMER_REDIRECT_TO_SHOP = 2500;

const IRRIGATION_SIGNAL_COUNTRIES = ['NL'];

// DO NOT CHANGE ORDERING!
const PRODUCT = {
  BASIC: 'basic', // TerraSphere
  PRO: 'pro', // TerraSphere
  HD: 'hd', // TerraSphere
  SOIL_ZONE: 'soil-zone', // TerraSphere
  IRRIGATION_SIGNAL: 'irrigation-signal' // WUR
}

const PRODUCT_TYPE = {
  SUBSCRIPTION: 'subscription',
  SINGLE_PURCHASE: 'single-purchase'
}

export default {
  TIMER_REDIRECT_TO_SHOP,
  IRRIGATION_SIGNAL_COUNTRIES,
  PRODUCT,
  PRODUCT_TYPE
};
