/**
 * guid
 *  Guid helper method
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

export default function guid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);

    return v.toString(16);
  });
}
