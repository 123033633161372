/**
 *
 * Views
 *  View configurations (Navigation/Panel/Edit)
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const NAVIGATION = {
  BENCHMARK: 'benchmark',
  CONFIGURATION: 'configuration',
  FIELDS: 'fields',
  INFO: 'info',
  NOTES: 'notes',
  SHOP: 'shop'
}

const PANEL = {
  FIELD: {
    BENCHMARK: {
      INDEX: 'field.field-benchmark',
      DETAIL: 'field.field-benchmark-detail'
    },
    GRAPHS: 'field.field-graphs',
    FIELD: {
      INDEX: 'field.field-data',
      EDIT: 'field.field-data-edit'
    },
    IRRIGATIONS: 'field.field-irrigations'
  },
  SHOP: {
    INDEX: 'shop.shop-index',
    PRODUCT: 'shop.shop-product',
    SUBSCRIPTION: {
      INDEX: 'shop.shop-subscription-index',
      COMPLETE: 'shop.shop-subscription-complete'
    },
    ORDER: {
      INDEX: 'shop.shop-order-index',
      COMPLETE: 'shop.shop-order-complete'
    }
  }
};

export default {
  NAVIGATION,
  PANEL
};
