import Api from '@/services/api'

export default {
  getAllIrrigationAdvices () {
    return Api().get('/fields/irrigation-advice');
  },
  getFieldIrrigations (fieldId) {
    return Api().get(`/fields/${fieldId}/irrigations`);
  },
  getFieldIrrigationsAdvice (fieldId) {
    return Api().get(`/fields/${fieldId}/irrigations/advice`);
  },
  createFieldIrrigation (fieldId, requestBody) {
    return Api().post(`/fields/${fieldId}/irrigations`, requestBody);
  },
  updateFieldIrrigation (fieldId, irrigationId, requestBody) {
    return Api().put(`/fields/${fieldId}/irrigations/${irrigationId}`, requestBody);
  },
  deleteFieldIrrigation (fieldId, irrigationId) {
    return Api().delete(`/fields/${fieldId}/irrigations/${irrigationId}`);
  }
}
