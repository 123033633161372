const state = {
  field: null,
  season: null,
  peerGroup: [],
  activeProductCode: ''
}

const actions = {
  setField ({ commit }, field) {
    commit('SET_FIELD', field);
  },
  setSeason ({ commit }, season) {
    commit('SET_SEASON', season);
  },
  setPeerGroup ({ commit }, peerGroup) {
    commit('SET_PEER_GROUP', (peerGroup || []));
  },
  setActiveProductCode ({ commit }, productCode) {
    commit('SET_ACTIVE_PRODUCT_CODE', productCode);
  }
}

const mutations = {
  SET_FIELD (state, field) {
    state.field = field;
  },
  SET_SEASON (state, season) {
    state.season = season;
  },
  SET_PEER_GROUP (state, peerGroup) {
    state.peerGroup = peerGroup;
  },
  SET_ACTIVE_PRODUCT_CODE (state, productCode) {
    state.activeProductCode = productCode;
  }
}

const getters = {
  field: state => state.field,
  fieldId: state => (state.field && state.field.id ? state.field.id : null),
  season: state => state.season,
  peerGroup: state => state.peerGroup,
  activeProductCode: state => state.activeProductCode
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
