/**
 *
 * Global
 *
 * Global constants
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import httpHelper from '@/helpers/http';
import guid from '@/helpers/guid';

const DOMAIN = process.env.VUE_APP_DOMAIN || httpHelper.getDomain();
const OAUTH_CLIENT_ID = process.env.VUE_APP_OAUTH_CLIENT_ID || null;
const OAUTH_CLIENT_SECRET = process.env.VUE_APP_OAUTH_CLIENT_SECRET || null;
const LOCAL_STORAGE = process.env.VUE_APP_LOCAL_STORAGE || 'fieldscout';
const LOCAL_STORAGE_AUTH_KEY = 'auth';
const LOCAL_STORAGE_LOCALE_KEY = 'locale';
const GTM_ID = process.env.VUE_APP_GTM || 'GTM-WMC9R7N';
const API_URL = process.env.VUE_APP_API_URL || `${DOMAIN}/api`;
const ASSETS_PATH = '';
const ICON_PADDING_RATIO = 1.15; // Same as SCSS variable!
const DEBOUNCE_MS = 600;
const TIMER_LOGOUT = 1500;
const SPINNER_GUID = guid();

export default {
  DOMAIN,
  OAUTH_CLIENT_ID,
  OAUTH_CLIENT_SECRET,
  LOCAL_STORAGE,
  LOCAL_STORAGE_AUTH_KEY,
  LOCAL_STORAGE_LOCALE_KEY,
  GTM_ID,
  API_URL,
  ASSETS_PATH,
  ICON_PADDING_RATIO,
  DEBOUNCE_MS,
  TIMER_LOGOUT,
  SPINNER_GUID
};
