import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const NotesRepository = RepositoryFactory.get('notes');

const state = {
  note: null,
  notes: [],
  visible: true
}

const actions = {
  reset ({ commit }) {
    commit('RESET_NOTES');
  },
  toggleVisibility ({ commit, state }, visibility) {
    const visible = visibility || !state.visible;
    commit('TOGGLE_VISIBILITY', visible)
  },
  getAllNotes ({ commit, state }, reload) {
    return new Promise((resolve, reject) => {
      if (!reload && (state.notes && state.notes.length)) {
        resolve(state.notes)
      } else {
        return NotesRepository.getAllNotes().then(response => {
          const notes = response.data.data;
          commit('SET_NOTES', notes);
          resolve(notes);
        }).catch((error) => {
          commit('SET_NOTES');
          reject(error);
        });
      }
    })
  },
  updateNoteById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return NotesRepository.updateNoteById(payload.noteId, payload.fieldId, payload.requestBody).then(response => {
        const notes = response.data.data;
        resolve(notes);
      }).catch((error) => {
        reject(error);
      });
    })
  },
  deleteNote ({ commit }, note) {
    return NotesRepository.deleteNoteById(note.id, note.field_id)
  },
  deletePhotoById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return NotesRepository.deletePhotoById(payload.noteId, payload.fieldId, payload.photoId).then(response => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    })
  },
  removeNewNote ({ commit }) {
    commit('REMOVE_NEW_NOTE');
  },
  createNote ({ commit }, note) {
    return new Promise((resolve) => {
      commit('ADD_NEW_NOTE', note);
      resolve(note)
    });
  },
  saveNote ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      return NotesRepository.createNote(payload.fieldId, payload.requestBody).then(response => {
        const note = response.data.data;
        commit('REPLACE_NEW_NOTE', note);
        resolve(note)
      }).catch((error) => {
        reject(error)
      });
    })
  },
  setCurrentNote ({ commit }, note) {
    commit('SET_CURRENT_NOTE', note);
  }
}

const mutations = {
  RESET_NOTES (state) {
    state.notes = [];
  },
  TOGGLE_VISIBILITY (state, visiblity) {
    state.visible = visiblity;
  },
  SET_NOTES (state, notes) {
    state.notes = notes || [];
  },
  SET_CURRENT_NOTE (state, note) {
    state.note = note;
  },
  ADD_NEW_NOTE (state, note) {
    const noteIndex = state.notes.findIndex(note => note.id === 'new');

    if (noteIndex !== -1) {
      state.notes[noteIndex] = note;
    } else {
      state.notes.push(note);
    }

    // Clone the array to trigger any watchers
    state.notes = [...state.notes]
    state.note = note;
  },
  REPLACE_NEW_NOTE (state, note) {
    state.notes = [
      ...state.notes.filter(note => note.id !== 'new'),
      note
    ]
  },
  REMOVE_NEW_NOTE () {
    const i = state.notes.findIndex(note => note.id === 'new');

    if (i === -1) return;

    state.notes.splice(i, 1);
  }
}

const getters = {
  isVisible: state => state.visible,
  getNote: state => state.note,
  getNotes: state => state.notes,
  getNotesByFieldId: (state) => (fieldId) => {
    if (!state.notes) return;
    return state.notes.filter(note => note.field_id === fieldId);
  },
  getNoteById: (state) => (id) => {
    if (!state.notes || !state.notes.length) return [];

    return state.notes.find(note => note.id === id)
  },
  getNewNote () {
    return state.notes.find(note => note.id === 'new')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
