/**
 * RepositoryFactory.js
 *
 * Factory to define all API repositories
 *
 * @author     Ken van den Broek    <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import BenchmarkRepository from './BenchmarkRepository';
import FieldRepository from './FieldRepository';
import IrrigationRepository from './IrrigationRepository';
import NotesRepository from './NotesRepository';
import ShopRepository from './ShopRepository';
import SubscriptionsRepository from './SubscriptionsRepository';
import UserRepository from './UserRepository';

const repositories = {
  benchmark: BenchmarkRepository,
  field: FieldRepository,
  irrigation: IrrigationRepository,
  notes: NotesRepository,
  shop: ShopRepository,
  subscriptions: SubscriptionsRepository,
  user: UserRepository
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
