import { RepositoryFactory } from '@/repositories/RepositoryFactory'

const SubscriptionsRepository = RepositoryFactory.get('subscriptions');

const state = {
  subscriptions: []
}

const actions = {
  setup ({ commit }, data) {
    return new Promise((resolve, reject) => {
      return SubscriptionsRepository.setup(data).then(response => {
        const order = response.data;
        resolve(order);
      }).catch((response) => {
        reject(response);
      });
    });
  },
  addFieldsToSubscription ({ commit }, { subscriptionId, ids }) {
    return new Promise((resolve, reject) => {
      return SubscriptionsRepository.addFieldsToSubscription(subscriptionId, { ids }).then(response => {
        const result = response.data.data;
        resolve(result);
      }).catch((response) => {
        reject(response);
      });
    });
  },
  get ({ commit }) {
    return new Promise((resolve, reject) => {
      return SubscriptionsRepository.get().then(response => {
        const subscriptions = typeof response !== 'object' ? [] : response.data.data;
        commit('SET_SUBSCRIPTIONS', subscriptions);
        resolve(subscriptions);
      }).catch((error) => {
        commit('SET_SUBSCRIPTIONS', []);
        reject(error);
      });
    });
  }
}

const mutations = {
  SET_SUBSCRIPTIONS (state, subscriptions) {
    state.subscriptions = subscriptions;
  }
}

const getters = {
  subscriptions (state) {
    return state.subscriptions;
  },
  getSubscriptionByType: (state) => (type) => {
    return state.subscriptions.find(subscription => subscription.subscription_type.slug === type);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
