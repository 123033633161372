/**
 * Storage helper
 *
 * Locale storage helper
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

// Accessing constants through config service
//  not working from this context (due to weird webpack error)...
import GLOBAL from '@/constants/global';
import LOCALE from '@/constants/locale';

const STORAGE = GLOBAL.LOCAL_STORAGE;

export default {
  getAuth () {
    const AUTH_KEY = GLOBAL.LOCAL_STORAGE_AUTH_KEY;
    const auth = window.localStorage.getItem(`${STORAGE}/${AUTH_KEY}`);

    return auth ? JSON.parse(auth) : null;
  },
  removeAuth () {
    const AUTH_KEY = GLOBAL.LOCAL_STORAGE_AUTH_KEY;

    window.localStorage.removeItem(`${STORAGE}/${AUTH_KEY}`);
  },
  getLocale () {
    const LOCALE_DEFAULT = LOCALE.DEFAULT;
    const LOCALE_KEY = GLOBAL.LOCAL_STORAGE_LOCALE_KEY;
    const locale = window.localStorage.getItem(`${STORAGE}/${LOCALE_KEY}`);

    return locale ? JSON.parse(locale) : LOCALE_DEFAULT;
  }
}
