/**
 *
 * Vuex
 *
 * Vuex constants
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const MODULES = {
  BENCHMARK: 'benchmark',
  FIELDS: 'fields',
  GRAPHS: 'graphs',
  INTERACTIVE_LIST: 'interactiveList',
  IRRIGATION: 'irrigation',
  MAP: 'map',
  NOTES: 'notes',
  ROUTE: 'route',
  SHOP: 'shop',
  SUBSCRIPTIONS: 'subscriptions',
  UI: 'ui',
  USERS: 'users'
}

const INITIAL_STATE = {
  self: true,
  modules: {
    [MODULES.BENCHMARK]: {
      self: true
    },
    [MODULES.FIELDS]: {
      self: true
    },
    [MODULES.GRAPHS]: {
      self: true
    },
    [MODULES.INTERACTIVE_LIST]: {
      self: true
    },
    [MODULES.IRRIGATION]: {
      self: true
    },
    [MODULES.MAP]: {
      self: true
    },
    [MODULES.NOTES]: {
      self: true
    },
    [MODULES.ROUTE]: {
      self: false
    },
    [MODULES.SHOP]: {
      self: true
    },
    [MODULES.SUBSCRIPTIONS]: {
      self: true
    },
    [MODULES.UI]: {
      self: true
    },
    [MODULES.USERS]: {
      self: false
    }
  }
}

export default {
  MODULES,
  INITIAL_STATE
}
