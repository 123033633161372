/**
 * Config
 *  Service which returns general and static configurations...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import constants from '@/constants/_constants';
import objectHelper from '@/helpers/object';

export default {
  get (prop, data) {
    let configValue = objectHelper.get(prop, constants);

    if (typeof data !== 'undefined') {
      configValue = objectHelper.mergeDeep(configValue, data);
    }

    return configValue;
  }
}
