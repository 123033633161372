/**
 * Localization
 *  Service which returns the i18nConfig object...
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import storageHelper from '@/helpers/storage';
import urlHelper from '@/helpers/url';

const locale = urlHelper.getQueryParam('locale');

Vue.use(VueI18n);

const i18nConfig = {
  locale: locale || storageHelper.getLocale(),
  messages: {}
};

try {
  i18nConfig.messages[i18nConfig.locale] = require('../lang/' + `${i18nConfig.locale}.json`);
} catch (ex) {
  i18nConfig.messages[i18nConfig.locale] = require('../lang/nl-NL.json'); // HARDCODED FALLBACK!
}

export default new VueI18n(i18nConfig);
