/**
 *
 * Irrigations
 *
 * @author     K. van den Broek <k.vandenbroek@uselab.com>
 * @copyright  Uselab Amsterdam
 *
 */

const KEY_MM = 'amount'
const KEY_IRRIGATED_AT = 'irrigated_at'
const KEY_FIELD_NAME = 'field_name'
const KEY_DATE = 'date'

export default {
  KEY_MM,
  KEY_IRRIGATED_AT,
  KEY_FIELD_NAME,
  KEY_DATE
}
